import React from 'react';
import { Modal } from './Modal';
import { Button } from '../buttons/Button';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  title?: string;
  content: string;
};

const InfoModal = ({ title, content, isOpen, closeModal }: ModalProps) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        title={title || 'Thông báo'}
        classNameTitle="text-[20px]"
        onClose={closeModal}
        className={`max-w-[480px]`}
      >
        <div className="pt-3">
          <p className="mb-10">{content}</p>
          <Button className="w-full" variant="primary" size="large" onClick={() => closeModal()}>
            Đóng
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default InfoModal;
