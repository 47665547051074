import React from 'react';
import { DevelopmentIcon } from 'assets/icons';

type Props = {
  className?: string;
};

export const ComingSoonFeature = ({ className }: Props) => {
  return (
    <div
      className={`inline-flex flex-col items-center text-center ${className}`}
    >
      <DevelopmentIcon />
      <p className="text-base font-normal leading-[22px] text-text_main_med">
        Tính năng đang phát triển
      </p>
    </div>
  );
};
