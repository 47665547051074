import { Modal } from 'components/modal/Modal';
import React, { useEffect, useState } from 'react';
import {
  Button,
  DetailStockAnalysis,
  DetailStockCategory,
  DetailStockNews,
  DetailStockRecords,
  InfoTooltip,
} from '../index';
import { ACTION_ORDER, ConfigService, FirestoreService, MarketStockService } from 'core';
import { getDoc } from 'firebase/firestore';
import { useAccount, useMarket } from 'core/contexts';
import { StockBuyingPowerType, StockDataType } from 'core/types/StockRecords.type';
import { formatStockPrice } from 'core/utils';
import { HammerIcon } from 'assets/icons';
import { useNavigate } from 'react-router-dom';

type StockDetailModelProps = {
  isShowStockDetailModel: boolean;
  setIsShowStockDetailModel: (isShow: boolean) => void;
  stockCode: string;
};

enum DetailStockTab {
  // TRANSACTION,
  ANALYSIS,
  CATEGORY,
  NEWS,
  RECORDS,
}

const tabOptions = [
  // {
  //   title: 'Giao dịch',
  //   value: DetailStockTab.TRANSACTION
  // },
  {
    title: 'Danh mục',
    value: DetailStockTab.CATEGORY,
  },
  {
    title: 'Phân tích',
    value: DetailStockTab.ANALYSIS,
  },
  {
    title: 'Tin tức',
    value: DetailStockTab.NEWS,
  },
  {
    title: 'Hồ sơ',
    value: DetailStockTab.RECORDS,
  },
];

const StockDetailModel = ({
  isShowStockDetailModel,
  setIsShowStockDetailModel,
  stockCode,
}: StockDetailModelProps) => {
  const [tab, setTab] = useState(DetailStockTab.RECORDS);
  const [stockData, setStockData] = useState<StockDataType>();
  const { getStatuByMarket, getMarketName } = useMarket();
  const { account } = useAccount();
  const [stockBuyingPower, setStockBuyingPower] = useState<StockBuyingPowerType>();
  const [metadata, setMetadata] = useState<any>();
  const navigator = useNavigate();

  const fetchStockData = async (symbol: string) => {
    const res = await getDoc(FirestoreService.readStockSymbol(symbol));
    const data = res?.data();
    setStockData(data as StockDataType);
  };

  const formatNumber = (v: number) => {
    if (!v) {
      return '--';
    }
    if (v > 1000000000000) {
      return `${parseFloat((v / 1000000000000).toFixed(1)).toLocaleString('en-US')}k tỷ`;
    }
    if (v > 1000000000) {
      return `${parseFloat((v / 1000000000).toFixed(1)).toLocaleString('en-US')}tỷ`;
    }
    if (v > 1000000) {
      return `${parseFloat((v / 1000000).toFixed(1)).toLocaleString('en-US')}tr`;
    }
    if (v > 10000) {
      return `${parseFloat((v / 1000).toFixed(1)).toLocaleString('en-US')}k`;
    }
  };

  const fetchStockBuyingPower = async () => {
    if (stockData && account) {
      const paramsStock = {
        accountNo: account?.account_id,
        stockSymbol: stockData?.symbol,
        exchange: stockData?.market_id,
        price: stockData?.last_price,
      };
      const response = await MarketStockService.getStockBuyingPower(paramsStock);
      if (response?.data) {
        const data = response?.data?.data;
        setStockBuyingPower(data);
      }
    }
  };

  const getMetadata = async () => {
    const data = await ConfigService.getMetaData();
    setMetadata(data);
  };

  const getDescription = (key: string) => {
    return metadata?.[key];
  };

  useEffect(() => {
    if (stockData) {
      fetchStockBuyingPower();
    }
  }, [stockData, account]);

  useEffect(() => {
    if (stockCode) {
      fetchStockData(stockCode);
    }
    getMetadata();
  }, [stockCode]);

  return (
    <Modal
      isOpen={isShowStockDetailModel}
      hasCloseBtn
      onClose={() => {
        setIsShowStockDetailModel(false);
        setTimeout(() => {
          setTab(DetailStockTab.RECORDS);
        }, 1000);
      }}
      title={''}
      className={`max-w-[100vw] h-[666px] max-h-[666px] !p-6 rounded-md`}
      closeBtnClassName={`!text-[28px] mt-[6px]`}
      isBackground
      isCloseBackground
    >
      <div className="h-[100%]">
        <div className="pb-6 flex justify-between mt-[-32px] mr-[68px]">
          <div className="flex gap-[82px]">
            <div className="flex flex-col gap-1">
              <p className="text-4xl font-semibold leading-9">{stockCode}</p>
              <div className="flex items-center gap-[2px]">
                <span className="text-sm font-normal leading-5">
                  {getMarketName(stockData?.market_id)} -{' '}
                  {getStatuByMarket(stockData?.market_id || '').label}
                </span>
                <span
                  className="w-3 h-3 block rounded-full"
                  style={{
                    backgroundColor: getStatuByMarket(stockData?.market_id as string).color,
                  }}
                ></span>
              </div>
            </div>
            <div className="flex gap-[64px] min-w-[437px] text-xs font-normal leading-[18px] tracking-[-0.6px]">
              <div className="flex justify-between min-w-[155px]">
                <div className="flex flex-col gap-1">
                  <p>GTGD</p>
                  <p>KLGD</p>
                  <div className="flex gap-[2px] items-center">
                    <p>Tỉ lệ ký quỹ</p>
                    <InfoTooltip title={getDescription('MTA_ORDER_TOOLTIP_MARGIN_RATE')} />
                  </div>
                </div>
                <div className="flex flex-col gap-1 items-end">
                  <p>{formatNumber(stockData?.total_val || 0)}</p>
                  <p>
                    {stockData?.total_vol ? (stockData?.total_vol).toLocaleString('en-US') : '--'}
                  </p>
                  <p>{(stockBuyingPower?.margin_ratio || 0) * 100}%</p>
                </div>
              </div>
              <div className="flex justify-between w-1/3">
                <div className="flex flex-col gap-1">
                  <p>TC</p>
                  <p>Trần</p>
                  <p>Sàn</p>
                </div>
                <div className="flex flex-col gap-1 items-end font-semibold tracking-normal">
                  <p className="text-mustardYellow">
                    {stockData?.ref_price ? formatStockPrice(stockData?.ref_price) : '0'}
                  </p>
                  <p className="text-ceiling_color">
                    {stockData?.ceiling ? formatStockPrice(stockData?.ceiling) : '0'}
                  </p>
                  <p className="text-floor_color">
                    {stockData?.floor ? formatStockPrice(stockData?.floor) : '0'}
                  </p>
                </div>
              </div>
              <div className="flex justify-between w-1/3">
                <div className="flex flex-col gap-1">
                  <p>TB</p>
                  <p>Cao</p>
                  <p>Thấp</p>
                </div>
                <div className="flex flex-col gap-1 items-end font-semibold tracking-normal">
                  <p className="text-mustardYellow">
                    {stockData?.close ? formatStockPrice(stockData.close) : '0'}
                  </p>
                  <p className="text-high_price">
                    {stockData?.high ? formatStockPrice(stockData.high) : '0'}
                  </p>
                  <p className="text-reduce_color">
                    {stockData?.low ? formatStockPrice(stockData.low) : '0'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-12">
            <Button
              className="text-xl hover:!bg-primary hover:text-white"
              onClick={() =>
                navigator(`/stock-order?symbol=${stockCode}&action=${ACTION_ORDER.B.value}`)
              }
            >
              <HammerIcon type="secondary" />
              <p className="text-sm font-semibold leading-5">Đặt lệnh</p>
            </Button>
          </div>
        </div>
        <div className="flex gap-[30px] text-base font-semibold leading-[22px]">
          {tabOptions.map(item => {
            return (
              <div
                key={Math.floor(Math.random() * 10000000000)}
                className={`flex cursor-pointer ${
                  tab === item.value ? 'text-primary border-b border-solid border-primary pb-2' : ''
                }`}
                onClick={() => setTab(item.value)}
              >
                <p>{item.title}</p>
              </div>
            );
          })}
        </div>
        <div className="mt-4 max-h-[500px] overflow-scroll hidden-scrollbar">
          {/* {tab === DetailStockTab.TRANSACTION && <DetailStockTransaction />} */}
          {tab === DetailStockTab.ANALYSIS && (
            <DetailStockAnalysis stockCode={stockCode} lastPrice={stockData?.last_price || 0} />
          )}
          {tab === DetailStockTab.CATEGORY && <DetailStockCategory stockCode={stockCode} />}
          {tab === DetailStockTab.NEWS && <DetailStockNews stockCode={stockCode} />}
          {tab === DetailStockTab.RECORDS && <DetailStockRecords stockCode={stockCode} />}
        </div>
      </div>
    </Modal>
  );
};

export default StockDetailModel;
