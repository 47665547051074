import React, { useEffect, useState } from 'react';
import {
  TitleSection,
  Button,
  Modal,
  Countdown,
  ErrorToast,
  SuccessToast,
  SpinnerIcon,
} from 'components';
import { AccountService } from 'core';
import { toast } from 'react-toastify';
import {
  RiCheckboxCircleFill,
  RiErrorWarningLine,
  RiEyeLine,
  RiEyeOffLine,
  RiMailFill,
  RiPhoneFill,
} from 'react-icons/ri';
import Select, { type DropdownIndicatorProps, components } from 'react-select';
import { RiArrowDownSFill } from 'react-icons/ri';
import { OnboardingService } from 'core/services';
import { MdErrorOutline } from 'react-icons/md';
import { SmartOtpGuideButton } from 'components/SmartOtpGuideButton';
import { ForgotPinButton } from 'components/ForgotPinButton';
import MultiCircleSupportBox from 'components/MultiCircleSupportBox';
import { sha256 } from 'js-sha256';
import { useAccount } from 'core/contexts';
import dayjs from 'dayjs';
import { HOST_URL_LANDING } from 'core/constant/api.constant';

const TWO_FACTOR_AUTHENTICATION_OPTIONS = [
  { value: 'DIGIT_CERT', label: 'Chứng thư số CA' },
  { value: 'SMART_OTP', label: 'Smart OTP' },
  { value: 'SMS_OTP', label: 'SMS OTP' },
  { value: 'PIN', label: 'PIN' },
];

enum TWO_FACTOR_AUTHENTICATION {
  DIGIT_CERT = 'DIGIT_CERT',
  SMART_OTP = 'SMART_OTP',
  SMS_OTP = 'SMS_OTP',
  PIN = 'PIN',
}

const myWindow = window as any;

const formatCountDown = (countDown: number) => {
  const minutes = Math.floor(countDown / 60);
  const seconds = countDown - minutes * 60;
  return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const TwoFactorAuthenticationPage = () => {
  const [factorAuthenType, setFactorAuthenType] = useState<
    'PIN' | 'SMART_OTP' | 'SMS_OTP' | 'DIGIT_CERT'
  >();
  const [method, setMethod] = useState();
  const [isShowMethod, setIsShowMethod] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showPinPopup, setShowPinPopup] = useState(false);
  const [pinCode, setPinCode] = useState('');
  const [smartOtp, setSmartOtp] = useState('');
  const [smsOtp, setSMSOtp] = useState('');
  const [certInfo, setCertInfo] = useState<any>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [tfaCurrentData, setTfaCurrentData] = useState<any>();
  const [error, setError] = useState<string>();
  const [confirmError, setConfirmError] = useState<string>();
  const [methodError, setMethodError] = useState<string>();
  const [countDown, setCountDown] = useState(0);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const { accountInfo, userInfo, updateUserInfo } = useAccount();
  const [onSubmit, setOnSubmit] = useState(false);
  const [isOpenSmartOtpModal, setIsOpenSmartOtpModal] = useState(false);
  const getSettings = async () => {
    const res = await AccountService.getSettings('TwoFactorAuthentication');
    const type = res?.data.data?.value;
    if (!type) {
      toast('Không thể lấy phương thức xác thực', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return;
    }
    setFactorAuthenType(type);
  };

  const getTFAMethod = async () => {
    const res = await OnboardingService.fetchSystemConfig();
    const data = res?.data?.find((i: any) => i.key === 'TFA_METHOD');
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <RiArrowDownSFill size={24} color="#FFFFFF" />
      </components.DropdownIndicator>
    );
  };

  const getUserData = async () => {
    const res = await AccountService.getUserInfo();
    if (res?.data?.status === 'Success') {
      updateUserInfo(res?.data?.data);
    }
  };

  const checkVnptPlugin = () => {
    myWindow.checkVNPTPlugin();
  };

  const handleSign = async () => {
    const response = await myWindow.GetCertInfo();
    if (response) {
      setCertInfo(response);
    }
  };

  const getSmsOtp = async (userId: string) => {
    const res = await AccountService.getSmsOTPTfa(userId);
    const data = res?.data;
    if (data?.status === 'Fail') {
      ErrorToast(data?.error_message.split('|')[1] || data?.error_message);
    }
    if (data?.status === 'Success') {
      setCountDown(180);
    }
  };

  const reSendOTP = async () => {
    setCountDown(180);
    getSmsOtp(userInfo?.user_id as string);
  };

  const handleMethodChange = (value: any) => {
    setMethod(value);
    setIsShowMethod(false);
    setMethodError('');
    setTermsAccepted(false);
    setCertInfo(null);
  };

  const clearData = () => {
    setIsShowConfirmModal(false);
    setCertInfo(null);
    setIsShowMethod(false);
    setMethod(undefined);
    setPinCode('');
    setSmartOtp('');
    setSMSOtp('');
    setMethodError('');
    setCountDown(0);
    setTermsAccepted(false);
  };

  const handleRegisterDigitCertificate = async () => {
    let params = {};
    let ca_params = {};

    if (factorAuthenType === TWO_FACTOR_AUTHENTICATION.PIN) {
      if (pinCode.length < 6) {
        setError('Mã PIN không hợp lệ');
        return;
      } else {
        params = {
          pin: sha256(pinCode),
          otp: '',
        };

        if (method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) {
          ca_params = {
            otp: '',
            pin: sha256(pinCode),
            digital_certificate: {
              cert_base64: certInfo?.base64,
              serial_id: certInfo?.serial,
              subject_cn: certInfo?.subjectCN,
              issuer_cn: certInfo?.issuerCN,
              not_before: dayjs(certInfo?.notBefore).add(7, 'hours').toISOString(),
              not_after: dayjs(certInfo?.notAfter).add(7, 'hours').toISOString(),
              tax_number: certInfo?.taxNumber,
              personal_id: certInfo?.personalId || '',
              passport: certInfo?.passport,
            },
            factor_authen_enum_type: method,
          };
        }
      }
    } else if (factorAuthenType === TWO_FACTOR_AUTHENTICATION.SMART_OTP) {
      if (smartOtp.length < 6) {
        setError('Mã Smart OTP không hợp lệ');
        return;
      } else {
        params = {
          pin: '',
          otp: smartOtp,
        };

        if (method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) {
          ca_params = {
            otp: smartOtp,
            pin: '',
            digital_certificate: {
              cert_base64: certInfo?.base64,
              serial_id: certInfo?.serial,
              subject_cn: certInfo?.subjectCN,
              issuer_cn: certInfo?.issuerCN,
              not_before: dayjs(certInfo?.notBefore).add(7, 'hours').toISOString(),
              not_after: dayjs(certInfo?.notAfter).add(7, 'hours').toISOString(),
              tax_number: certInfo?.taxNumber,
              personal_id: certInfo?.personalId || '',
              passport: certInfo?.passport,
            },
            factor_authen_enum_type: method,
          };
        }
      }
    } else if (factorAuthenType === TWO_FACTOR_AUTHENTICATION.SMS_OTP) {
      if (smsOtp.length < 6) {
        setError('Mã SMS OTP không hợp lệ');
        return;
      } else {
        params = {
          pin: '',
          otp: smsOtp,
        };

        if (method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) {
          ca_params = {
            otp: smsOtp,
            pin: '',
            digital_certificate: {
              cert_base64: certInfo?.base64,
              serial_id: certInfo?.serial,
              subject_cn: certInfo?.subjectCN,
              issuer_cn: certInfo?.issuerCN,
              not_before: dayjs(certInfo?.notBefore).add(7, 'hours').toISOString(),
              not_after: dayjs(certInfo?.notAfter).add(7, 'hours').toISOString(),
              tax_number: certInfo?.taxNumber,
              personal_id: certInfo?.personalId || '',
              passport: certInfo?.passport,
            },
            factor_authen_enum_type: method,
          };
        }
      }
    }

    if (factorAuthenType !== TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) {
      const res = await AccountService.tfaCurrentVerify(params);
      if (res?.data?.status === 'Fail') {
        setError(res?.data?.error_message.split('|')[1]);
        return;
      } else {
        setError('');
        setTfaCurrentData(res?.data?.data);
      }
    }

    if (method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) {
      setOnSubmit(true);
      const res = await AccountService.registerDigitCertificate(ca_params);
      if (res?.data?.status === 'Success') {
        SuccessToast('Thay đổi phương thức xác thực thành công');
        getSettings();
        clearData();
        setOnSubmit(false);
      } else {
        ErrorToast(res?.data?.error_message || 'Thay đổi phương thức xác thực thất bại');
        setOnSubmit(false);
      }
    } else if (
      method === TWO_FACTOR_AUTHENTICATION.SMART_OTP ||
      method === TWO_FACTOR_AUTHENTICATION.PIN
    ) {
      setIsShowConfirmModal(true);
    } else if (method === TWO_FACTOR_AUTHENTICATION.SMS_OTP) {
      setIsShowConfirmModal(true);
      reSendOTP();
    }
  };

  const handleConfirm = async () => {
    setOnSubmit(true);
    let params = {
      otp: '',
      pin: '',
      transaction_id: tfaCurrentData?.transaction_id,
      digital_certificate: {},
      factor_authen_enum_type: method,
    };

    if (method === TWO_FACTOR_AUTHENTICATION.SMS_OTP) {
      if (smsOtp.length < 6) {
        setConfirmError('Mã SMS OTP không hợp lệ');
        return;
      }
      params = {
        ...params,
        otp: smsOtp,
      };
    } else if (method === TWO_FACTOR_AUTHENTICATION.PIN) {
      if (pinCode.length < 6) {
        setConfirmError('Mã PIN không hợp lệ');
        return;
      }
      params = {
        ...params,
        pin: sha256(pinCode),
      };
    } else if (method === TWO_FACTOR_AUTHENTICATION.SMART_OTP) {
      if (smartOtp.length < 6) {
        setConfirmError('Mã Smart OTP không hợp lệ');
        return;
      }
      params = {
        ...params,
        otp: smartOtp,
      };
    }

    if (
      factorAuthenType === TWO_FACTOR_AUTHENTICATION.SMART_OTP ||
      factorAuthenType === TWO_FACTOR_AUTHENTICATION.PIN ||
      factorAuthenType === TWO_FACTOR_AUTHENTICATION.SMS_OTP
    ) {
      const res = await AccountService.tfaChangeMethodByTransacion(params);
      if (res?.data?.status === 'Success') {
        SuccessToast('Thay đổi phương thức xác thực thành công');
        setIsShowConfirmModal(false);
        getSettings();
        clearData();
      } else {
        ErrorToast(
          res?.data?.error_message?.split('|')[1] || 'Thay đổi phương thức xác thực thất bại'
        );
      }
    } else {
      delete params.transaction_id;

      params = {
        ...params,
        digital_certificate: {
          cert_base64: certInfo?.base64,
          serial_id: certInfo?.serial,
          subject_cn: certInfo?.subjectCN,
          issuer_cn: certInfo?.issuerCN,
          not_before: dayjs(certInfo?.notBefore).add(7, 'hours').toISOString(),
          not_after: dayjs(certInfo?.notAfter).add(7, 'hours').toISOString(),
          tax_number: certInfo?.taxNumber,
          personal_id: certInfo?.personalId || '',
          passport: certInfo?.passport,
        },
        factor_authen_enum_type: method,
      };

      setOnSubmit(true);
      const res = await AccountService.changeDigitCertificate(params);
      if (res?.data?.status === 'Success') {
        SuccessToast('Thay đổi phương thức xác thực thành công');
        getSettings();
        clearData();
        setOnSubmit(false);
      } else {
        ErrorToast(
          res?.data?.error_message?.split('|')[1] || 'Thay đổi phương thức xác thực thất bại'
        );
        setOnSubmit(false);
      }
    }

    setOnSubmit(false);
  };

  const handleCountDown = () => {
    setCountDown(countDown - 1);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (countDown > 0) {
      timeoutId = setTimeout(() => {
        handleCountDown();
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [countDown]);

  const handleDisableConfirmButton = () => {
    if (factorAuthenType == TWO_FACTOR_AUTHENTICATION.PIN) {
      if (method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) {
        return !certInfo || !termsAccepted || !pinCode;
      } else {
        return !termsAccepted || !pinCode;
      }
    } else if (factorAuthenType == TWO_FACTOR_AUTHENTICATION.SMART_OTP) {
      if (method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) {
        return !certInfo || !termsAccepted || !smartOtp;
      } else {
        return !termsAccepted || !smartOtp;
      }
    } else if (factorAuthenType == TWO_FACTOR_AUTHENTICATION.SMS_OTP) {
      if (method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) {
        return !certInfo || !termsAccepted || !smsOtp;
      } else {
        return !termsAccepted || !smsOtp;
      }
    } else if (factorAuthenType == TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) {
      return !certInfo || !termsAccepted;
    }
  };

  const checkIsMac = () => {
    return navigator.userAgent.toUpperCase().includes('MAC');
  };

  useEffect(() => {
    getSettings();
    getTFAMethod();
    getUserData();
  }, []);

  return (
    <div className="flex p-2 gap-2">
      <div className="w-full border !border-t-0 bg-main_background_white rounded-lg flex flex-col flex-1 h-fit">
        <TitleSection title="Đổi xác thực 2 yếu tố" />
        <div className="pb-6 px-4 pt-4 flex flex-col gap-8">
          <div className="form-input">
            <label htmlFor="" className="form-input__label">
              Phương thức xác thực đang sử dụng
            </label>
            <input
              className="form-input__input form-input__input_disabled !bg-blackBlue"
              value={
                (factorAuthenType === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT
                  ? 'Chứng thư số CA'
                  : factorAuthenType) || '-'
              }
              readOnly
            ></input>
          </div>
          <div className="form-input">
            <label htmlFor="" className="text-sm font-normal leading-5">
              Chọn phương thức xác thực 2 yếu tố
            </label>
            <Select
              className="react-select"
              classNamePrefix="select"
              isSearchable={true}
              name="TwoFactorAuthentication"
              placeholder="--"
              options={TWO_FACTOR_AUTHENTICATION_OPTIONS.filter(i => i.value !== factorAuthenType)}
              value={TWO_FACTOR_AUTHENTICATION_OPTIONS.find(i => i.value === method) || null}
              onChange={(e: any) => {
                handleMethodChange(e?.value);
              }}
              components={{ DropdownIndicator }}
            />
            {methodError && (
              <div className="mt-[2px]">
                <p className="text-sm font-normal leading-5 text-carminePink">{methodError}</p>
              </div>
            )}
          </div>
          {method === TWO_FACTOR_AUTHENTICATION.SMS_OTP && isShowMethod && (
            <div className="form-input">
              <label className="!text-sm !leading-5">Phương thức nhận mã kích hoạt</label>
              <input
                disabled
                // make phone fomat like 0123****123
                value={`SMS OTP (${
                  userInfo?.phone_number
                    ? userInfo?.phone_number.replace(/(\d{3})\d{4}(\d{3})/, '$1****$2')
                    : ''
                })`}
                className="form-input__input !bg-blackBlue form-input__input_disabled"
                placeholder="Nhập mã PIN"
              />
            </div>
          )}
          {factorAuthenType === TWO_FACTOR_AUTHENTICATION.PIN && isShowMethod && (
            <div>
              <div className="relative form-input">
                <label htmlFor="pin" className="!text-sm !leading-5">
                  Mã PIN
                </label>
                <input
                  id="pin"
                  value={pinCode}
                  type={showPin ? 'text' : 'password'}
                  className={`form-input__input ${error && 'form-input__input_error'} !bg-black`}
                  placeholder="Nhập mã PIN"
                  maxLength={6}
                  onChange={e => {
                    const v = e.target.value;
                    setPinCode(v);
                    setError('');
                  }}
                />
                <button
                  onClick={() => setShowPin(!showPin)}
                  type="button"
                  className="absolute right-4 bottom-0 transform -translate-y-1/2 disabled:opacity-50"
                >
                  {showPin ? (
                    <RiEyeLine color="#98A2B3" size={20} />
                  ) : (
                    <RiEyeOffLine color="#98A2B3" size={20} />
                  )}
                </button>
              </div>
              <div className={`mt-1 ${error ? 'flex justify-between' : 'text-right'}`}>
                {error && <p className="text-sm font-normal leading-5 text-carminePink">{error}</p>}
                <ForgotPinButton />
              </div>
            </div>
          )}
          {!isShowMethod && (
            <div className="w-full">
              <Button
                onClick={() => {
                  if (method) {
                    if (
                      method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT ||
                      factorAuthenType === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT
                    ) {
                      checkVnptPlugin();
                    }
                    if (method === TWO_FACTOR_AUTHENTICATION.SMART_OTP && !accountInfo?.smart_otp) {
                      // setMethodError('Bạn chưa đăng ký Smart OTP');
                      setIsOpenSmartOtpModal(true);
                    } else {
                      setIsShowMethod(true);
                    }
                  }
                }}
                disabled={!method}
                className="w-[250px] float-right"
              >
                Tiếp tục
              </Button>
            </div>
          )}
          {factorAuthenType === TWO_FACTOR_AUTHENTICATION.SMS_OTP && isShowMethod && (
            <div>
              <div className="relative form-input">
                <div className="flex justify-between">
                  <label htmlFor="sms-otp" className="!text-sm !leading-5">
                    Mã SMS OTP
                  </label>
                  {countDown > 0 && (
                    <p className="text-sm font-normal leading-5">
                      Mã có hiệu lực trong{' '}
                      <span className="font-semibold">{formatCountDown(countDown)}</span>
                    </p>
                  )}
                </div>
                <input
                  id="sms-otp"
                  value={smsOtp}
                  type={showPin ? 'text' : 'password'}
                  className={`form-input__input ${
                    error && 'form-input__input_error'
                  } !bg-blackBlue`}
                  placeholder="Nhập mã SMS OTP"
                  maxLength={6}
                  onChange={e => {
                    const v = e.target.value;
                    setSMSOtp(v);
                    setError('');
                  }}
                />
                <button
                  onClick={() => setShowPin(!showPin)}
                  type="button"
                  className="absolute right-4 bottom-0 transform -translate-y-1/2 disabled:opacity-50"
                >
                  {showPin ? (
                    <RiEyeLine color="#98A2B3" size={20} />
                  ) : (
                    <RiEyeOffLine color="#98A2B3" size={20} />
                  )}
                </button>
              </div>
              <div className="mt-[6px]">
                {error && <p className="text-sm font-normal leading-5 text-carminePink">{error}</p>}
                {countDown > 0 ? (
                  <>
                    <p className="text-xs font-normal leading-[18px]">
                      Vui lòng nhập mã OTP vừa gửi tới{' '}
                      <span className="font-semibold">{userInfo?.phone_number}</span>
                    </p>
                    <div className="text-xs font-normal leading-[18px]">
                      Bạn chưa nhận được mã?
                      <button
                        className="font-semibold text-primary ml-2 disabled:opacity-50"
                        onClick={() => {
                          if (countDown > 0) return;
                          reSendOTP();
                        }}
                      >
                        <p className={`font-semibold ${countDown > 0 ? 'text-gray' : ''}`}>
                          Gửi lại mã OTP
                        </p>
                      </button>
                    </div>
                  </>
                ) : (
                  <button
                    className="text-xs leading-[18px] font-semibold text-primary disabled:opacity-50"
                    onClick={() => {
                      if (countDown > 0) return;
                      reSendOTP();
                    }}
                  >
                    <p className={`font-semibold ${countDown > 0 ? 'text-gray' : ''}`}>
                      Gửi mã OTP
                    </p>
                  </button>
                )}
              </div>
            </div>
          )}
          {factorAuthenType === TWO_FACTOR_AUTHENTICATION.SMART_OTP &&
            isShowMethod &&
            method !== TWO_FACTOR_AUTHENTICATION.DIGIT_CERT && (
              <div>
                <div className="relative form-input">
                  <label htmlFor="smart-otp" className="!text-sm !leading-5">
                    Mã Smart OTP
                  </label>
                  <input
                    id="smart-otp"
                    value={smartOtp}
                    type={showPin ? 'text' : 'password'}
                    className={`w-full bg-black border border-lineMed rounded text-sm pl-4 pr-10 py-2 outline-none placeholder:text-[#98A2B3]`}
                    placeholder="Nhập mã Smart OTP"
                    maxLength={6}
                    onChange={e => {
                      const v = e.target.value;
                      setSmartOtp(v);
                      setError('');
                    }}
                  />
                  <button
                    onClick={() => setShowPin(!showPin)}
                    type="button"
                    className="absolute right-4 bottom-0 transform -translate-y-1/2 disabled:opacity-50"
                  >
                    {showPin ? (
                      <RiEyeLine color="#98A2B3" size={20} />
                    ) : (
                      <RiEyeOffLine color="#98A2B3" size={20} />
                    )}
                  </button>
                </div>
                <div className={`${error ? 'flex justify-between' : 'text-right'} mt-1`}>
                  {error && (
                    <p className="text-sm font-normal leading-5 text-carminePink">{error}</p>
                  )}
                  <SmartOtpGuideButton />
                </div>
              </div>
            )}
          {method !== TWO_FACTOR_AUTHENTICATION.DIGIT_CERT &&
            factorAuthenType !== TWO_FACTOR_AUTHENTICATION.DIGIT_CERT &&
            isShowMethod && (
              <div className="checkbox">
                <input
                  id="show-pin-checkbox"
                  type="checkbox"
                  className={'!w-6 !h-6 checkbox__input'}
                  onChange={e => {
                    setTermsAccepted(e.target.checked);
                  }}
                />
                <label
                  htmlFor="show-pin-checkbox"
                  className="text-sm font-normal leading-5 checkbox__label two_factor_checkbox"
                >
                  Tôi đã đọc và đồng ý với{' '}
                  <a
                    href={`${HOST_URL_LANDING}/sbbs-terms-and-policies`}
                    rel="noreferrer"
                    target="_blank"
                    className="text-primary"
                  >
                    Điều khoản sử dụng và Chính sách bảo mật
                  </a>
                </label>
              </div>
            )}
          {(method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT ||
            factorAuthenType === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT) &&
            isShowMethod && (
              <div className="flex flex-col gap-8">
                {factorAuthenType !== TWO_FACTOR_AUTHENTICATION.DIGIT_CERT && (
                  <>
                    <div className="p-4 flex gap-2 bg-blackBlueLight rounded">
                      <div className="w-6">
                        <MdErrorOutline color="#00C802" size={24} />
                      </div>
                      <div className="flex flex-1">
                        <p className="text-primary text-sm font-normal leading-5">
                          Lưu ý: Phương thức xác thực Chứng thư số CA này chỉ hỗ trợ cho kênh giao
                          dịch Webtrading khi đăng nhập trên máy tính. Hiện tại Quý khách chỉ có thể
                          dùng phương thức xác thực qua Chứng thư số CA đối với các loại giao dịch
                          trên web cắm USB chứng thư. Đối với giao dịch qua ứng dụng điện thoại, Quý
                          khách vẫn sử dụng phương thức xác thực PIN. <br /> <br />
                          Hướng dẫn cài đặt ứng dụng VNPT-CA trên máy tính{' '}
                          <a
                            href={`https://kyso.vnpt-ca.vn/Authen/PluginVersion?product=${
                              checkIsMac() ? '6' : '2'
                            }`}
                            rel="noreferrer"
                            target="_blank"
                            className="underline"
                          >
                            tại đây.
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="checkbox">
                      <input
                        id="show-pin-checkbox"
                        type="checkbox"
                        className={'!w-6 !h-6 checkbox__input'}
                        onChange={e => {
                          setTermsAccepted(e.target.checked);
                        }}
                      />
                      <label
                        htmlFor="show-pin-checkbox"
                        className="text-sm font-normal leading-5 checkbox__label two_factor_checkbox"
                      >
                        Tôi đã đọc và đồng ý với{' '}
                        <a
                          href={`${HOST_URL_LANDING}/sbbs-terms-and-policies`}
                          rel="noreferrer"
                          target="_blank"
                          className="text-primary"
                        >
                          Điều khoản sử dụng và Chính sách bảo mật
                        </a>
                      </label>
                    </div>
                  </>
                )}

                <div>
                  <div className="flex justify-between">
                    <p
                      className={`text-sm ${
                        factorAuthenType !== TWO_FACTOR_AUTHENTICATION.DIGIT_CERT
                          ? 'font-semibold'
                          : 'font-normal'
                      } leading-5`}
                    >
                      {factorAuthenType !== TWO_FACTOR_AUTHENTICATION.DIGIT_CERT
                        ? 'Đăng ký xác thực bằng chứng thư số'
                        : 'Chứng thư số'}
                    </p>
                    {certInfo && (
                      <p
                        className="text-base font-semibold leading-[22px] text-green-700 cursor-pointer"
                        onClick={handleSign}
                      >
                        Chọn lại
                      </p>
                    )}
                  </div>
                  <div className="mt-4">
                    <table className="cert-table">
                      <thead>
                        <th>Tên chứng thư số</th>
                        <th>Serial</th>
                        <th>Ngày hết hạn</th>
                      </thead>
                      <tbody>
                        {certInfo && (
                          <tr>
                            <td>{certInfo?.subjectCN}</td>
                            <td>{certInfo?.serial}</td>
                            <td>{certInfo?.notAfter}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {!certInfo && (
                      <tr className="pt-[47px] flex flex-col items-center justify-center w-full">
                        <p className="text-sm leading-5 text-text_main_light">
                          Chưa có chứng thư số. Vui lòng tìm chứng thư số và chọn tiếp tục.
                        </p>
                        <Button
                          className="font-semibold mt-8 w-[200px]"
                          variant="secondary"
                          onClick={handleSign}
                        >
                          <span className="text-base leading-[22px]">Chọn chứng thư số</span>
                        </Button>
                      </tr>
                    )}
                  </div>
                  {factorAuthenType === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT && isShowMethod && (
                    <div className="checkbox mt-[81px]">
                      <input
                        id="show-pin-checkbox"
                        type="checkbox"
                        className={'!w-6 !h-6 checkbox__input'}
                        onChange={e => {
                          setTermsAccepted(e.target.checked);
                        }}
                      />
                      <label
                        htmlFor="show-pin-checkbox"
                        className="text-sm font-normal leading-5 checkbox__label two_factor_checkbox"
                      >
                        Tôi đã đọc và đồng ý với{' '}
                        <a
                          href={`${HOST_URL_LANDING}/sbbs-terms-and-policies`}
                          rel="noreferrer"
                          target="_blank"
                          className="text-primary"
                        >
                          Điều khoản sử dụng và Chính sách bảo mật
                        </a>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )}
          {factorAuthenType === TWO_FACTOR_AUTHENTICATION.SMART_OTP &&
            isShowMethod &&
            method === TWO_FACTOR_AUTHENTICATION.DIGIT_CERT && (
              <div>
                <div className="relative form-input">
                  <label htmlFor="smart-otp" className="!text-sm !leading-5">
                    Mã Smart OTP
                  </label>
                  <input
                    id="smart-otp"
                    value={smartOtp}
                    type={showPin ? 'text' : 'password'}
                    className={`w-full bg-black border border-lineMed rounded text-sm pl-4 pr-10 py-2 outline-none placeholder:text-[#98A2B3]`}
                    placeholder="Nhập mã Smart OTP"
                    maxLength={6}
                    onChange={e => {
                      const v = e.target.value;
                      setSmartOtp(v);
                      setError('');
                    }}
                  />
                  <button
                    onClick={() => setShowPin(!showPin)}
                    type="button"
                    className="absolute right-4 bottom-0 transform -translate-y-1/2 disabled:opacity-50"
                  >
                    {showPin ? (
                      <RiEyeLine color="#98A2B3" size={20} />
                    ) : (
                      <RiEyeOffLine color="#98A2B3" size={20} />
                    )}
                  </button>
                </div>
                <div className={`${error ? 'flex justify-between' : 'text-right'} mt-1`}>
                  {error && (
                    <p className="text-sm font-normal leading-5 text-carminePink">{error}</p>
                  )}
                  <SmartOtpGuideButton />
                </div>
              </div>
            )}
          {isShowMethod && (
            <div
              className={`w-full ${
                method !== TWO_FACTOR_AUTHENTICATION.DIGIT_CERT ? 'mt-[47px]' : 'mt-[79px]'
              }`}
            >
              <Button
                onClick={handleRegisterDigitCertificate}
                className="w-[250px] float-right flex gap-1 items-center"
                disabled={handleDisableConfirmButton()}
              >
                {onSubmit && <SpinnerIcon type="secondary" size="small" />}
                Xác nhận
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="w-[38%] flex flex-col gap-2">
        <div className="box-items">
          <MultiCircleSupportBox
            sizeCircle_1="w-[44.22px] h-[44.22px]"
            sizeCircle_2="w-[75.28px] h-[75.28px]"
            sizeCircle_3="w-[112px] h-[112px]"
            colorCircle_1="bg-primary"
            colorCircle_2="bg-[#154C13]"
            colorCircle_3="bg-[#162D16]"
          >
            <RiPhoneFill color="#fff" className="w-[22.4px] h-[22.4px]" />
          </MultiCircleSupportBox>
          <div className="flex flex-col gap-4 mt-6">
            <p className="text-lg font-semibold leading-6">Hỗ trợ qua điện thoại</p>
            <p className="text-base font-normal leading-[22px] text-text_main_light">
              Bạn cần hỗ trợ trực tiếp, hãy gọi cho tổng đài của SBBS số
              <span className="font-bold text-white"> 19002648 </span> ngay nhé.
            </p>
          </div>
        </div>
        <div className="box-items">
          <MultiCircleSupportBox
            sizeCircle_1="w-[44.22px] h-[44.22px]"
            sizeCircle_2="w-[75.28px] h-[75.28px]"
            sizeCircle_3="w-[112px] h-[112px]"
            colorCircle_1="bg-primary"
            colorCircle_2="bg-[#154C13]"
            colorCircle_3="bg-[#162D16]"
          >
            <RiMailFill color="#fff" className="w-[22.4px] h-[22.4px]" />
          </MultiCircleSupportBox>
          <div className="flex flex-col gap-4 mt-6">
            <p className="text-lg font-semibold leading-6">Hỗ trợ qua email</p>
            <p className="text-base font-normal leading-[22px] text-text_main_light">
              Bạn cần hỗ trợ trực tiếp, hãy liên hệ với SBBS thông qua email
              <span className="font-bold text-white"> info@sbbs.com.vn. </span>
            </p>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isShowConfirmModal}
        title="Xác nhận đăng ký "
        classNameTitle=""
        subtitle={`
        ${
          method === TWO_FACTOR_AUTHENTICATION.SMART_OTP
            ? 'Vui lòng nhập mã xác nhận để hoàn tất quá trình đăng ký sử dụng phương thức xác thực Smart OTP'
            : method === TWO_FACTOR_AUTHENTICATION.SMS_OTP
            ? `Vui lòng nhập mã OTP vừa gửi tới ${userInfo?.phone_number} để hoàn tất quá trình đăng ký sử dụng phương thức xác thực SMS OTP`
            : method === TWO_FACTOR_AUTHENTICATION.PIN
            ? 'Vui lòng nhập mã PIN để hoàn tất quá trình đăng ký sử dụng phương thức xác thực PIN'
            : ''
        }
        `}
        subtitleClassName="text-sm !font-normal leading-5 text-white mt-1"
        onClose={() => {
          setIsShowConfirmModal(false);
        }}
        className={`max-w-[640px]`}
        type="lock"
      >
        <div className="mt-4">
          {method === TWO_FACTOR_AUTHENTICATION.SMART_OTP && (
            <>
              <div className="relative form-input">
                <label htmlFor="smart-otp" className="!text-sm !leading-5 mb-[10px]">
                  Mã Smart OTP
                </label>
                <input
                  id="smart-otp"
                  value={smartOtp}
                  type={showPinPopup ? 'text' : 'password'}
                  className={`form-input__input ${
                    confirmError && 'form-input__input_error'
                  } !bg-blackBlue`}
                  placeholder="Nhập mã Smart Otp"
                  maxLength={6}
                  onChange={e => {
                    const v = e.target.value;
                    setSmartOtp(v);
                    setConfirmError('');
                  }}
                />
                <button
                  onClick={() => setShowPinPopup(!showPinPopup)}
                  type="button"
                  className="absolute right-4 bottom-0 transform -translate-y-1/2 disabled:opacity-50"
                >
                  {showPinPopup ? (
                    <RiEyeLine color="#98A2B3" size={20} />
                  ) : (
                    <RiEyeOffLine color="#98A2B3" size={20} />
                  )}
                </button>
              </div>
              {confirmError && (
                <div className="mt-1">
                  <p className="text-sm font-normal leading-5 text-carminePink">{confirmError}</p>
                </div>
              )}
            </>
          )}
          {method === TWO_FACTOR_AUTHENTICATION.PIN && (
            <>
              <div className="relative form-input">
                <label htmlFor="pin" className="!text-sm !leading-5 mb-[10px]">
                  Mã PIN giao dịch
                </label>
                <input
                  id="pin"
                  value={pinCode}
                  type={showPinPopup ? 'text' : 'password'}
                  className={`form-input__input ${
                    confirmError && 'form-input__input_error'
                  } !bg-blackBlue`}
                  placeholder="Nhập mã PIN"
                  maxLength={6}
                  onChange={e => {
                    const v = e.target.value;
                    setPinCode(v);
                    setConfirmError('');
                  }}
                />
                <button
                  onClick={() => setShowPinPopup(!showPinPopup)}
                  type="button"
                  className="absolute right-4 bottom-0 transform -translate-y-1/2 disabled:opacity-50"
                >
                  {showPinPopup ? (
                    <RiEyeLine color="#98A2B3" size={20} />
                  ) : (
                    <RiEyeOffLine color="#98A2B3" size={20} />
                  )}
                </button>
              </div>
              <div className={`${confirmError ? 'flex justify-between' : 'text-right'} mt-1`}>
                {confirmError && (
                  <p className="text-sm font-normal leading-5 text-carminePink">{confirmError}</p>
                )}
                <ForgotPinButton />
              </div>
            </>
          )}
          {method === TWO_FACTOR_AUTHENTICATION.SMS_OTP && (
            <>
              <div className="relative form-input">
                <div className="flex justify-between">
                  <label htmlFor="sms-otp" className="!text-sm !leading-5 mb-[10px]">
                    Mã SMS OTP
                  </label>
                  <p className="text-sm font-normal leading-5">
                    Mã có hiệu lực trong{' '}
                    <span className="font-semibold">{formatCountDown(countDown)}</span>
                  </p>
                </div>
                <input
                  id="sms-otp"
                  value={smsOtp}
                  type={showPinPopup ? 'text' : 'password'}
                  className={`form-input__input ${
                    confirmError && 'form-input__input_error'
                  } !bg-blackBlue`}
                  placeholder="Nhập mã SMS OTP"
                  maxLength={6}
                  onChange={e => {
                    const v = e.target.value;
                    setSMSOtp(v);
                    setConfirmError('');
                  }}
                />
                <button
                  onClick={() => setShowPinPopup(!showPinPopup)}
                  type="button"
                  className="absolute right-4 bottom-0 transform -translate-y-1/2 disabled:opacity-50"
                >
                  {showPinPopup ? (
                    <RiEyeLine color="#98A2B3" size={20} />
                  ) : (
                    <RiEyeOffLine color="#98A2B3" size={20} />
                  )}
                </button>
              </div>
              <div className={`flex justify-between mt-4`}>
                {confirmError && (
                  <p className="text-sm font-normal leading-5 text-carminePink">{confirmError}</p>
                )}
                <div className="text-xs font-normal leading-[18px]">
                  Bạn chưa nhận được mã?
                  <button
                    className="font-semibold text-primary ml-2 disabled:opacity-50"
                    onClick={() => {
                      if (countDown > 0) return;
                      reSendOTP();
                    }}
                  >
                    <p className={`font-semibold ${countDown > 0 ? 'text-gray' : ''}`}>
                      Gửi lại mã OTP
                    </p>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <div className={`flex items-center gap-3 ${error ? 'mt-9' : 'mt-6'}`}>
          <Button
            className="flex-1"
            variant="secondary"
            onClick={() => {
              setIsShowConfirmModal(false);
            }}
          >
            Đóng
          </Button>

          <Button
            variant="primary"
            className="flex-1 flex gap-1 items-center"
            onClick={handleConfirm}
          >
            {onSubmit && <SpinnerIcon type="secondary" size="small" />}
            Xác nhận
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenSmartOtpModal}
        title={''}
        onClose={() => {
          setIsOpenSmartOtpModal(false);
        }}
        type="info"
        hasCloseBtn
        className={`max-w-[550px] h-auto !pt-6`}
      >
        <div className="flex flex-col gap-6 -mt-2">
          <p className="text-xl font-semibold leading-[26px]">Bạn chưa đăng ký Smart OTP</p>
          <div className="flex flex-col gap-3 text-base font-normal leading-[22px]">
            <p>
              Bước 1: Vào ứng dụng <span className="font-semibold">SBBS iTrade</span> trên điện
              thoại
            </p>
            <p>
              Bước 2: Vào <span className="font-semibold">Cài đặt</span> chọn{' '}
              <span className="font-semibold">Smart OTP</span>
            </p>
            <p>
              Bước 3: Vào mục <span className="font-semibold">Quản lý tài khoản</span> và chọn tài
              khoản đã đăng ký
            </p>
            <p>Bước 4: Nhập mã OTP hiển thị trên màn hình</p>
          </div>
        </div>
        <Button
          className="flex-1 mt-8 w-full"
          variant="primary"
          onClick={() => {
            setIsOpenSmartOtpModal(false);
          }}
        >
          Đóng
        </Button>
      </Modal>
    </div>
  );
};

export default TwoFactorAuthenticationPage;
