import React, { useEffect } from 'react';
import { Spinner } from 'components/spinner/Spinner';
import { useAuth } from 'react-oidc-context';
import { IS_HOLIDAY_NEXT_DATE, IS_HOLIDAY_TODAY } from 'core/constant/storage.constant';

const LogoutPage = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      localStorage.clear();
      auth.signoutRedirect();
      // auth.removeUser();
      // sessionStorage.clear();
    } else {
      //auth.signinRedirect();
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
    auth.signinRedirect,
  ]);

  return (
    <>
      <div className="flex">
        <Spinner />
      </div>
    </>
  );
};

export default LogoutPage;

// redirectMethod?: "replace" | "assign";
// redirectTarget?: "top" | "self";
