import React, { useState, useEffect } from 'react';
import { useAccount } from 'core/contexts/AccountContext';
import { AccountService, AssetsService } from 'core/services';
import { AssetStockItem } from 'core/types';
import { Table } from 'antd';
import { formatCurrency } from 'core/utils';
import { Button, ErrorToast, FavoriteSymbol, SellStocksModal } from 'components';
import { Link } from 'react-router-dom';

const { Column, ColumnGroup } = Table;

const StockPortfolio = () => {
  const { account } = useAccount();
  const [stockPortfolioData, setStockPortfolioData] = useState([]);
  const [isMutiSell, setIsMutiSell] = useState(false);
  const [isShowSellModal, setIsShowSellModal] = useState(false);
  const [dataSell, setDataSell] = useState<AssetStockItem[]>([]);

  const fetchStockPortfolio = async () => {
    if (account) {
      const res = await AssetsService.getStockPortfolio(account.account_id);
      const data = res?.data?.data;
      setStockPortfolioData(data);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchStockPortfolio();
      return () => {
        clearTimeout(timer);
      };
    }, 100);
    setDataSell([]);
  }, [account]);

  const handleCheckbox = (checked: boolean, stock: AssetStockItem) => {
    if (checked) {
      setDataSell(o => [...o, stock]);
    } else {
      const dataChecked = dataSell.filter(i => i.stock_symbol !== stock.stock_symbol);
      setDataSell(dataChecked);
    }
  };

  const handleSellAll = () => {
    const dataStockAvailable = stockPortfolioData.filter((i: any) => i.available_quantity > 0);
    setDataSell(dataStockAvailable);
    setIsShowSellModal(true);
  };

  const isChecked = (symbol: string) => {
    const stockChecked = dataSell.find(i => i.stock_symbol === symbol);
    if (stockChecked) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {stockPortfolioData && stockPortfolioData.length > 1 ? (
        <div className="mt-[-55px] mb-4">
          {isMutiSell ? (
            <Button
              variant="secondary"
              className="ml-auto"
              onClick={() => {
                setDataSell([]);
                setIsMutiSell(false);
              }}
            >
              Huỷ bán nhiều mã
            </Button>
          ) : (
            <Button
              variant="secondary"
              className="ml-auto !bg-blackBlueLight"
              onClick={() => setIsMutiSell(true)}
            >
              Bán nhiều mã
            </Button>
          )}
        </div>
      ) : null}
      <div className="overflow-x-auto scrollbar-horizontal">
        <Table
          dataSource={stockPortfolioData}
          className="asset_table min-w-[1200px]"
          pagination={false}
          showSorterTooltip={false}
          // scroll={{ x: 500 }}
        >
          <Column title="&nbsp;" dataIndex="start" key="start" className="w-4" />
          <Column
            title="Mã CK"
            dataIndex="stock_symbol"
            key="stock_symbol"
            sorter={(a: AssetStockItem, b: AssetStockItem) =>
              a.stock_symbol.localeCompare(b.stock_symbol)
            }
            width={'5%'}
            render={(value, record) => (
              <div className="flex items-center gap-2">
                {isMutiSell ? (
                  <div className="checkbox">
                    <input
                      id={value}
                      checked={isChecked(value)}
                      type="checkbox"
                      disabled={!record.available_quantity || record.available_quantity === 0}
                      className={'w-[20px] h-[20px] checkbox__input'}
                      onChange={e => {
                        if (!record.available_quantity || record.available_quantity === 0) {
                          return;
                        }
                        handleCheckbox(e.target.checked, record);
                      }}
                    />
                    <label htmlFor={value} className={'checkbox__icon'}>
                      <span className="check">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M2.66667 2.00098H13.3333C13.5101 2.00098 13.6797 2.07121 13.8047 2.19624C13.9298 2.32126 14 2.49083 14 2.66764V13.3343C14 13.5111 13.9298 13.6807 13.8047 13.8057C13.6797 13.9307 13.5101 14.001 13.3333 14.001H2.66667C2.48986 14.001 2.32029 13.9307 2.19526 13.8057C2.07024 13.6807 2 13.5111 2 13.3343V2.66764C2 2.49083 2.07024 2.32126 2.19526 2.19624C2.32029 2.07121 2.48986 2.00098 2.66667 2.00098V2.00098ZM7.33533 10.6676L12.0487 5.95364L11.106 5.01098L7.33533 8.78231L5.44933 6.89631L4.50667 7.83898L7.33533 10.6676Z"
                            fill="#00C802"
                          />
                        </svg>
                      </span>
                    </label>
                  </div>
                ) : (
                  <FavoriteSymbol symbol={value} />
                )}
                {value}
              </div>
            )}
          />

          <Column
            title="Tổng"
            dataIndex="total_quantity"
            key="total_quantity"
            align="right"
            width={'5%'}
            sorter={(a: AssetStockItem, b: AssetStockItem) => a.total_quantity - b.total_quantity}
            render={(value, record) => <>{formatCurrency(record.total_quantity)}</>}
          />

          <Column
            title="Khả dụng"
            dataIndex="available_quantity"
            key="available_quantity"
            align="right"
            width={'7%'}
            render={(value, record: AssetStockItem) => (
              <>{formatCurrency(record.available_quantity)}</>
            )}
          />

          <ColumnGroup title="Mua chờ về">
            <Column
              title="T0"
              dataIndex="t0_quantity"
              key="t0_quantity"
              align="center"
              width={'6%'}
              render={value => <>{value ? formatCurrency(value) : '-'}</>}
            />
            <Column
              title="T1"
              dataIndex="t1_quantity"
              key="t1_quantity"
              width={'6%'}
              render={value => <>{value ? formatCurrency(value) : '-'}</>}
            />
            <Column
              title="T2"
              dataIndex="t2_quantity"
              key="t2_quantity"
              width={'6%'}
              render={value => <>{value ? formatCurrency(value) : '-'}</>}
            />
          </ColumnGroup>
          <Column
            title="CP chờ bán"
            dataIndex="wait_for_sell_quantity"
            key="wait_for_sell_quantity"
            align="right"
            width={'5%'}
            render={value => <>{formatCurrency(value)}</>}
          />
          <Column
            title="Giá vốn"
            dataIndex="average_price"
            key="average_price"
            align="right"
            width={'6.5%'}
            sorter={(a: AssetStockItem, b: AssetStockItem) => a.average_price - b.average_price}
            render={value => <>{formatCurrency(value)}</>}
          />

          <Column
            title="Giá TT"
            dataIndex="market_price"
            key="market_price"
            align="right"
            width={'6.5%'}
            sorter={(a: AssetStockItem, b: AssetStockItem) => a.market_price - b.market_price}
            render={value => <>{formatCurrency(value)}</>}
          />
          <Column
            title="Vốn"
            dataIndex="capital_price"
            key="capital_price"
            align="right"
            width={'8%'}
            sorter={(a: AssetStockItem, b: AssetStockItem) =>
              a.average_price * a.total_quantity - b.average_price * b.total_quantity
            }
            render={(_, record) => (
              <>{formatCurrency(record.average_price * record.total_quantity)}</>
            )}
          />
          <Column
            title="Giá trị TT"
            dataIndex="market_value"
            key="market_value"
            align="right"
            width={'7%'}
            sorter={(a: AssetStockItem, b: AssetStockItem) =>
              a.market_price * a.total_quantity - b.market_price * b.total_quantity
            }
            render={(_, record) => (
              <>{formatCurrency(record.market_price * record.total_quantity)}</>
            )}
          />
          <Column
            title="Lãi/lỗ"
            dataIndex="change_amount"
            key="change_amount"
            align="right"
            width={'7%'}
            sorter={(a: AssetStockItem, b: AssetStockItem) => a.change_amount - b.change_amount}
            render={value => (
              <span className={`${value > 0 ? 'text-high_price' : 'text-reduce_color'}`}>
                {value > 0 ? '+' : ''}
                {formatCurrency(value)}
              </span>
            )}
          />
          <Column
            title="Lãi/lỗ(%)"
            dataIndex="change_percent"
            key="achange_percentge"
            align="right"
            width={'7%'}
            sorter={(a: AssetStockItem, b: AssetStockItem) => a.change_percent - b.change_percent}
            render={value => (
              <span className={`${value > 0 ? 'text-high_price' : 'text-reduce_color'}`}>
                {value > 0 ? '+' : ''}
                {value.toFixed(2) + '%'}
              </span>
            )}
          />
          <Column
            title="Quyền"
            dataIndex="mortgage_quantity"
            width={'7%'}
            key="mortgage_quantity"
            align="right"
            sorter={(a: AssetStockItem, b: AssetStockItem) =>
              a.mortgage_quantity - b.mortgage_quantity
            }
            render={value => <>{value ? formatCurrency(value) : '-'}</>}
          />
          <Column
            title="Hành động"
            dataIndex="stock_symbol"
            key="stock_symbol"
            align="right"
            render={value => (
              <div className="flex justify-end">
                <Link
                  to={`/stock-order?symbol=${value}&action=S`}
                  className="w-[50px] h-[26px] text-xs font-bold leading-[18px] rounded bg-carminePink flex items-center justify-center hover:text-white"
                >
                  Bán
                </Link>
              </div>
            )}
          />
          <Column title="&nbsp;" dataIndex="start" key="end" className="w-4" />
        </Table>
      </div>
      {stockPortfolioData && stockPortfolioData.length > 1 ? (
        <div>
          {isMutiSell ? (
            <div className="flex gap-4">
              <Button
                size="small"
                variant="red"
                disabled={dataSell.length === 0}
                className="mt-[50px] min-w-[189px]"
                onClick={() => setIsShowSellModal(true)}
              >
                Bán {` `}
                {dataSell.length}
                {` `} cổ phiếu được chọn
              </Button>
              <Button
                size="small"
                variant="red"
                className="mt-[50px] min-w-[189px]"
                onClick={() => handleSellAll()}
              >
                Bán tất cả
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}
      <SellStocksModal
        isOpen={isShowSellModal}
        stocks={dataSell}
        resetData={() => {
          setDataSell([]);
          fetchStockPortfolio();
        }}
        closeModal={() => setIsShowSellModal(false)}
      />
    </div>
  );
};

export default StockPortfolio;
