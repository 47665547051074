import React from 'react';

export const SelectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
      <path
        d="M7.73463 10.2152L8.67596 11.1902L14.32 5.34463L15.2626 6.32096L8.67596 13.1429L4.43329 8.74868L5.37596 7.77234L6.79263 9.2396L7.73463 10.2146V10.2152ZM7.73596 8.26258L11.0373 4.84265L11.9773 5.81622L8.67596 9.23615L7.73596 8.26258ZM5.85129 12.1672L4.90929 13.1429L0.666626 8.74868L1.60929 7.77234L2.55129 8.74799L2.55063 8.74868L5.85129 12.1672Z"
        fill="#00C802"
      />
    </svg>
  );
};
