import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { RiAddLine, RiSubtractLine } from 'react-icons/ri';

type Props = {
  value?: any;
  initValue?: number;
  stepSize: number;
  className?: string;
  placeholder?: string;
  onChange?: (value: number) => void;
  disabled?: boolean;
  disabledIncrease?: boolean;
  type?: 'price' | 'quanlity';
};

export const InputNumber = ({
  stepSize = 10,
  value,
  initValue = 0,
  placeholder,
  disabled,
  disabledIncrease,
  type = 'quanlity',
  onChange,
  className,
}: Props) => {
  const step = stepSize;
  const decimalScale = type === 'price' ? (stepSize < 100 ? 2 : 1) : 1;
  const onIncrease = () => {
    let v = 0;
    if (type === 'price') {
      if (value >= 1 && value < stepSize) {
        v = (value ? value : initValue) + 1;
      } else {
        v = (value ? value : initValue) + step;
      }
    } else {
      v = (value ? value : initValue) + step;
    }
    onChange && onChange(v);
  };

  const onDecrease = () => {
    if (value && type === 'quanlity') {
      if (!value) {
        return;
      }
      if (value < stepSize) {
        onChange && onChange(0);
        return;
      }
    }
    let v = 0;
    if (type === 'price') {
      if (value >= 1 && value <= stepSize) {
        if (value === 1) {
          return;
        }
        v = (value ? value : initValue) - 1;
      } else {
        v = (value ? value : initValue) - step;
      }
    } else {
      v = (value ? value : initValue) - step;
    }
    onChange && onChange(v);
  };

  const onValueChange = (v: any) => {
    const floatValue = v?.floatValue || undefined;
    onChange && onChange(floatValue);
  };

  return (
    <div className={`rounded-md border border-stroke_default flex overflow-hidden ${className}`}>
      <button
        className="block bg-tab_background p-2 h-[38px] w-10 rounded-tl-sm rounded-bl-sm flex items-center justify-center text-text_main_med disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={disabled || value === 0 || (!value && type === 'quanlity')}
        onClick={onDecrease}
      >
        <RiSubtractLine className="text-[28px] text-[#C0C4C0]" />
      </button>
      <CurrencyFormat
        className="bg-[transparent] p-2 flex-1 text-center text-sm placeholder:text-cadetGrey"
        value={value}
        placeholder={placeholder}
        onValueChange={onValueChange}
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={decimalScale}
      />
      <button
        className="block bg-tab_background p-2 h-[38px] w-10 rounded-tr-sm rounded-br-sm flex items-center justify-center text-text_main_med disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={disabledIncrease}
        onClick={onIncrease}
      >
        <RiAddLine className="text-[28px] text-[#C0C4C0]" />
      </button>
    </div>
  );
};
