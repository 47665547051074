import { SearchIcon } from 'assets/icons';
import React, { useEffect, useRef, useState } from 'react';
import StockOptionSelect from './StockOptionSelect';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useComponentVisible from 'core/hooks/UseComponentVisible';

const StockSearch = ({ disabled }: { disabled?: boolean }) => {
  const [params] = useSearchParams();
  const navigator = useNavigate();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const symbolParam = params.get('symbol') || '';
  const [symbol, setSymbol] = useState<string>('');

  return (
    <div ref={ref} className="relative">
      <input
        value={isComponentVisible ? symbol : symbolParam}
        type="text"
        className="input-search-stock border"
        onFocus={() => {
          setSymbol('');
          setIsComponentVisible(true);
        }}
        disabled={disabled}
        onChange={e => {
          setSymbol(e.target.value);
        }}
      />
      <div className="p-2 bg-tab_background w-10 h-[38px] rounded-r-[6px] absolute right-[1px] top-[1px] flex justify-center items-center cursor-pointer">
        <SearchIcon className="w-[25px] h-[25px]" color="#C0C4C0" />
      </div>

      {isComponentVisible && (
        <StockOptionSelect
          symbol={symbol}
          onSelectItem={v => {
            setIsComponentVisible(false);
            navigator(`/stock-order?symbol=${v}&action=B`);
          }}
        />
      )}
    </div>
  );
};

export default StockSearch;
