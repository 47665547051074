import React, { useState, useEffect } from 'react';
import { useAccount } from 'core/contexts/AccountContext';
import { TitleSection, ComingSoonFeature } from 'components';
import { AssetsService } from 'core/services';
import { ProfitChart } from 'components';
import { formatCurrency } from 'core/utils';
import StockItem from 'components/StockItem';
import ProfitStock from './components/ProfitStock';

const ProfitPage = () => {
  const { account } = useAccount();
  const [cashAsset, setcashAsset] = useState<any>();

  const profitData = [
    {
      date: '1/2',
      percent: -15.2,
      amount: -100000,
    },
    {
      date: '2/2',
      percent: 15.2,
      amount: -100000,
    },
    {
      date: '3/2',
      percent: 12.2,
      amount: -100000,
    },
    {
      date: '4/2',
      percent: -15.2,
      amount: -100000,
    },
    {
      date: '5/2',
      percent: 10.2,
      amount: -100000,
    },
    {
      date: '6/2',
      percent: -15.2,
      amount: -100000,
    },
  ];

  const fetchcashAsset = async () => {
    if (account) {
      const res = await AssetsService.getCashPortfolio(account.account_id);
      const data = res?.data?.data;
      setcashAsset(data);
    }
  };

  useEffect(() => {
    fetchcashAsset();
  }, [account]);

  return (
    <div className="min-h-full flex items-center justify-center">
      <ComingSoonFeature />

      {/* <TitleSection title="Cấu thành lời/lỗ" />
      <div className="flex p-4">
        <div className="flex-1">
          <div className="text-sm mb-2">Giao dịch mua bán</div>
          <div className="text-base font-medium text-high_price">
            +{formatCurrency(80000000)}
          </div>
        </div>
        <div className="flex-1">
          <div className="text-sm mb-2">Cổ tức</div>
          <div className="text-base font-medium text-high_price">
            +{formatCurrency(80000000)}
          </div>
        </div>
        <div className="flex-1">
          <div className="text-sm mb-2">Lãi ký quỹ</div>
          <div className="text-base font-medium text-reduce_color">
            -{formatCurrency(80000000)}
          </div>
        </div>
        <div className="flex-1">
          <div className="text-sm mb-2">Lãi tiền gửi</div>
          <div className="text-base font-medium text-high_price">
            {formatCurrency(80000000)}
          </div>
        </div>
      </div>
      <TitleSection title="Cấu thành lời/lỗ" />
      <div className="p-4">
        <ProfitChart />
      </div>
      <TitleSection title="Lịch lời/lỗ" />
      <div className="p-4 overflow-x-auto">
        <div className="flex gap-2 ">
          {profitData.map(i => (
            <div
              key={i.date}
              className="flex-1 bg-blackBlue p-4 rounded min-w-[200px]"
            >
              <div className="text-text_main_light mb-2">{i.date}</div>
              <div
                className={`rounded border px-2 py-4 ${
                  i.percent < 0 ? 'text-reduce_color' : 'text-high_price'
                }`}
              >
                <div className="flex justify-between">
                  <span className="text-text_main_light text-sm">% Lời/lỗ</span>
                  <span className={`text-sm`}>
                    {i.percent > 0 ? '+' : ''}
                    {i.percent}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-text_main_light text-sm">Lời/lỗ</span>
                  <span className={`text-sm`}>{formatCurrency(i.amount)}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <TitleSection title="Lời/lỗ theo cổ phiếu" />
        <ProfitStock />
      </div> */}
    </div>
  );
};

export default ProfitPage;
