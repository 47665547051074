import React, { useEffect, useState } from 'react';
import { InputNumber } from '../inputs/InputNumber';
import { OrderModal, Button } from 'components';
import {
  formatCurrency,
  getAccountName,
  getAccountDescription,
  isHidePopupConfirm,
  checkSavePin,
  getDigitCertRequest,
} from 'core/utils';
import { useAccount } from 'core/contexts/AccountContext';
import { MarketStockService, ConfigService } from 'core';
import { toast } from 'react-toastify';
import { RiCheckboxCircleFill, RiErrorWarningLine } from 'react-icons/ri';
import { InfoTooltip } from 'components';
import { OrderType } from 'core/types';
import { getColorStatusOrder } from 'core/utils';
import { InputPrice } from 'components/inputs/InputPrice';
import dayjs from 'dayjs';
import { GENERAL_SERVER_ERROR } from 'core/constant';

const myWindow = window as any;

export const StockOrderUpdateForm = ({
  stockBuyingPower,
  stockData,
  stockInfo,
  order,
  priceStock,
  onSuccess,
}: {
  stockData?: any;
  stockInfo?: any;
  order: OrderType;
  stockBuyingPower?: any;
  priceStock?: any;
  onSuccess: () => void;
}) => {
  const [price, setPrice] = useState<number | string>();
  const [quanlity, setQuanlity] = useState<any>();
  const [stockHolding, setStockHolding] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState<any>('LO');
  const [showPIN, setShowPIN] = useState(false);
  const { account, userInfo } = useAccount();
  const [metadata, setMetadata] = useState<any>();
  const [systemConfig, setSystemConfig] = useState<any>();
  const [quanlityError, setQuanlityError] = useState<string>();
  const [certInfo, setCertInfo] = useState<any>();

  const getMetadata = async () => {
    const data = await ConfigService.getMetaData();
    setMetadata(data);
  };

  const fetchSystemConfig = async () => {
    const data = await ConfigService.getSystemConfig();
    setSystemConfig(data);
  };

  useEffect(() => {
    getMetadata();
    fetchSystemConfig();
    setPrice(order?.order_price);
    setQuanlity(order?.order_quantity);
    setOrderType(order?.matching_type);
  }, [order]);

  useEffect(() => {
    if (priceStock) {
      setPrice(priceStock);
    }
  }, [priceStock]);

  const getDescription = (key: string) => {
    return metadata?.[key];
  };

  const getSystemConfig = (key: string) => {
    return systemConfig ? JSON.parse(systemConfig?.[key]) : '';
  };

  const getStepPrice = () => {
    const config = getSystemConfig('ORDER_STEP_PRICE');
    let step = 10;

    if (stockData?.instrument_type_id === 'ETF') {
      step = config?.ETF?.StepPrice || 10;
    } else {
      const stepConfigMarket = config?.Stock?.[stockData?.market_id];
      const stepPriceMarket = stepConfigMarket?.find((i: any) => {
        if (i?.Min < stockData?.last_price && stockData?.last_price < i?.Max) {
          return i;
        }
      });
      step = stepPriceMarket?.StepPrice || 10;
    }
    return step;
  };

  const validatePrice = (value: number) => {
    if (value * 1000 > stockInfo?.ceiling || value * 1000 < stockInfo?.floor) {
      return 'Giá không nằm trong biên độ';
    }

    const step = getStepPrice();

    const price = +(value * 1000).toFixed(2);

    if (price % step !== 0) {
      let msg = `Cổ phiếu ${stockData?.symbol} đang có bước giá là ${step}đ. Vui lòng nhập đúng bước giá này. Quý khách có thế nhập:`;

      let last = price - (price % step);
      for (let i = 0; i < 3; i++) {
        last = last + step;
        msg =
          msg +
          ' ' +
          (last / 1000).toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }) +
          (i === 2 ? '' : ',');
      }

      msg = msg + '...';
      return msg;
    }

    return null;
  };

  const fetchStockHolding = async () => {
    if (order?.stock_symbol && account) {
      const paramsData = {
        accountNo: account?.account_id,
        stockSymbol: order?.stock_symbol,
      };
      const response = await MarketStockService.getStockHolding(paramsData);
      if (response?.data) {
        const data = response?.data?.data;
        setStockHolding(data);
      }
    }
  };

  useEffect(() => {
    fetchStockHolding();
  }, [account, order]);

  const submit = async () => {
    if (!price && orderType === 'LO') {
      toast('Giá đặt không hợp lệ.', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return;
    }

    if (quanlity > order.order_quantity) {
      toast('Không cho phép tăng số lượng.', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return;
    }

    if (
      !quanlity || quanlity > stockInfo?.lot_size ? quanlity % stockInfo?.lot_size !== 0 : false
    ) {
      toast('Khối lượng không hợp lệ.', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return;
    }

    if (order?.order_quantity >= 100 && quanlity < 100) {
      toast('Khối lượng không hợp lệ', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return;
    }

    if (price && typeof price === 'number' && validatePrice(price)) {
      return;
    }

    const pinSaved = await isHidePopupConfirm();
    if (pinSaved) {
      updateOrder(pinSaved);
      return;
      return;
    }

    setShowPIN(true);
  };

  const updateOrder = async (
    pin: string,
    type?: 'PIN' | 'SMART_OTP' | 'SMS_OTP' | 'DIGIT_CERT',
    isCheckSavePinCode?: boolean
  ) => {
    if (quanlity && order) {
      setLoading(true);
      const priceData =
        orderType === 'LO'
          ? typeof price === 'number'
            ? price * 1000
            : Number(price) * 1000
          : stockData?.last_price;

      let paramsdata: any = {
        order_no: order.order_no,
        price: priceData,
        quantity: quanlity,
        pin: pin,
        otp: '',
        factor_authen_enum_type: type,
        note: '',
      };
      if (pin && (type === 'SMART_OTP' || type === 'SMS_OTP')) {
        paramsdata = {
          ...paramsdata,
          otp: pin,
          pin: '',
        };
      }

      if (type === 'DIGIT_CERT') {
        const data = {
          order_no: order.order_no,
          price: priceData,
          quantity: quanlity,
          note: '',
        };

        const digit_cert_request = await getDigitCertRequest(data, certInfo);
        paramsdata = {
          ...paramsdata,
          digit_cert_request,
        };

        // let signedData = '';

        // const handleSignCMS = (event: any) => {
        //   signedData = event.detail;
        //   paramsdata = {
        //     ...paramsdata,
        //     digit_cert_request: {
        //       original_data: JSON.stringify(data),
        //       signed_data: signedData ? JSON.parse(signedData)?.data : '',
        //       cert_base64: certInfo?.base64,
        //       signature_base64: '',
        //       serial_id: certInfo?.serial,
        //       subject_cn: certInfo?.subjectCN,
        //       issuer_cn: certInfo?.issuerCN,
        //       not_before: dayjs(certInfo?.notBefore).add(7, 'hours').toISOString(),
        //       not_after: dayjs(certInfo?.notAfter).add(7, 'hours').toISOString(),
        //       tax_number: certInfo?.taxNumber,
        //       personal_id: certInfo?.personalId || '',
        //       passport: certInfo?.passport,
        //     },
        //   };

        //   handleUpdateOrder(paramsdata, isCheckSavePinCode || false, pin, type);
        //   window.removeEventListener('SignCMSCompleted', handleSignCMS);
        // };

        // window.addEventListener('SignCMSCompleted', handleSignCMS);

        // try {
        //   await myWindow?.signCMS(JSON.stringify(data));
        // } catch (error) {
        //   console.log('SignCMS Error:', error);
        // }
      }

      await handleUpdateOrder(paramsdata, isCheckSavePinCode || false, pin, type);
    }
  };

  const handleUpdateOrder = async (
    paramsdata: any,
    isCheckSavePinCode: boolean,
    pin: string,
    type?: string
  ) => {
    try {
      const res = await MarketStockService.updateOrder(userInfo?.user_id || '', paramsdata);
      const data = res?.data;

      if (data?.status === 'Fail') {
        toast(data?.error_message.split('|')[1] || data?.error_message, {
          type: 'error',
          icon: <RiErrorWarningLine className="text-[#F04438]" />,
        });
        setLoading(false);
        return;
      }

      if (data?.status === 'Success') {
        toast(
          'Yêu cầu sửa lệnh đã được tiếp nhận. Quý khách vui lòng kiểm tra trạng thái lệnh trong sổ lệnh.',
          {
            type: 'success',
            icon: <RiCheckboxCircleFill className="text-[#00C802]" />,
          }
        );
        onSuccess();
        setShowPIN(false);
      }
      if (isCheckSavePinCode && type === 'PIN') {
        await checkSavePin(pin);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast(GENERAL_SERVER_ERROR, {
        type: 'error',
        icon: <RiErrorWarningLine className="text-error" />,
      });
    }
  };

  const handleChangeQuanlity = (v: number) => {
    setQuanlityError('');
    setQuanlity(v);

    if (order?.order_quantity >= 100 && v < 100) {
      setQuanlityError('Khối lượng không hợp lệ');
    }
  };

  return (
    <>
      <div className="p-4  xl:pr-6 flex flex-col gap-4">
        <div className="text-xs flex justify-between gap-2">
          <div className="flex gap-[2px]">
            <span className="tracking-[-0.6px]">{`Tài khoản: ${getAccountName(
              order.account_type
            )}`}</span>
            <InfoTooltip title={getAccountDescription(order.account_type, metadata)} />
          </div>
          <div className="flex gap-[2px] font-semibold">
            <span
              className={`${
                order.bid_ask_type === 'B'
                  ? 'text-[#00C802]'
                  : order.bid_ask_type === 'S'
                  ? 'text-[#F04438]'
                  : ''
              }`}
            >
              {order?.bid_ask_type === 'B' ? 'Mua' : order?.bid_ask_type === 'S' ? 'Bán' : ''}
            </span>{' '}
            <span className="text-[#98A2B3]">|</span>{' '}
            <span className="text-gray_dark">{order?.order_category}</span>
            <span className="text-[#98A2B3]">|</span>{' '}
            <span className={`${getColorStatusOrder(order.display_status)}`}>
              {order?.display_status_name}
            </span>
          </div>
        </div>
        <InputNumber
          value={quanlity}
          disabledIncrease={true}
          onChange={v => {
            handleChangeQuanlity(v);
          }}
          stepSize={stockInfo?.lot_size || 100}
          placeholder="Khối lượng"
          type={orderType === 'LO' ? 'price' : 'quanlity'}
        />
        {quanlityError && <span className="text-sm text-reduce_color">{quanlityError}</span>}

        <div className="flex justify-between">
          <div className="flex items-center gap-[4px]">
            <span className="text-sm">KL tối đa</span>
            <InfoTooltip title={getDescription('MTA_ORDER_TOOLTIP_MAXIMUM_VOLUME')} />
          </div>
          <div className="flex items-center gap-[2px]">
            <span
              className="text-sm text-high_price cursor-pointer"
              onClick={() => {
                setQuanlity(stockBuyingPower?.max_buy_qty);
              }}
            >
              {stockBuyingPower?.max_buy_qty
                ? (stockBuyingPower?.max_buy_qty).toLocaleString('en-US')
                : '0'}
            </span>
            <span className=" text-xs text-cadetGrey">|</span>
            <span
              className="text-sm text-reduce_color cursor-pointer"
              onClick={() => {
                setQuanlity(stockHolding?.quatity);
              }}
            >
              {stockHolding?.quatity ? (stockHolding?.quatity).toLocaleString('en-US') : 0}
            </span>
          </div>
        </div>
        {orderType !== 'LO' && (
          <div className="rounded-md border flex overflow-hidden">
            <button
              className="block bg-blackBlue p-2 h-[38px] w-10 rounded-tl-sm rounded-bl-sm flex items-center justify-center"
              disabled={true}
            >
              <span className="block w-[15px] h-[2px] bg-cadetGrey"></span>
            </button>
            <input
              value={orderType}
              className="bg-[transparent] p-2 flex-1 text-center text-sm placeholder:text-cadetGrey"
              onFocus={() => setOrderType('LO')}
            />

            <button
              className="block bg-blackBlue p-2 h-[38px] w-10 rounded-tr-sm rounded-br-sm flex items-center justify-center"
              disabled={true}
            >
              <span
                className="
                  relative block w-[14px] h-[2px] bg-cadetGrey
                  after:block after:h-[14px] after:w-[2px] after:bg-cadetGrey after:absolute after:bottom-1/2 after:right-1/2 after:translate-x-1/2 after:translate-y-1/2
                "
              ></span>
            </button>
          </div>
        )}
        {orderType === 'LO' && (
          <div>
            <InputPrice
              value={Number(price) || undefined}
              onChange={v => {
                setPrice(v);
              }}
              initValue={stockData?.last_price ? stockData?.last_price / 1000 : 0}
              type="price"
              placeholder="Giá"
              stepSize={getStepPrice()}
            />
            {price && typeof price === 'number' && !!validatePrice(price) ? (
              <div className="text-sm text-error mt-1">{validatePrice(price)}</div>
            ) : null}
          </div>
        )}
        <div className="flex justify-between">
          <div className="flex items-center gap-[4px]">
            <span className="text-sm">Tỉ lệ hỗ trợ</span>
            <InfoTooltip title={getDescription('MTA_ORDER_TOOLTIP_MARGIN_RATE')} />
          </div>
          <div className="text-sm">{(stockBuyingPower?.margin_ratio || 0) * 100}%</div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[4px]">
            <span className="text-sm">Sức mua</span>
            <InfoTooltip title={getDescription('MTA_ORDER_TOOLTIP_PURCHASING_ABILITY')} />
          </div>
          <div className="text-sm">{formatCurrency(stockBuyingPower?.buying_power)}</div>
        </div>
        <Button variant="primary" className="" onClick={() => submit()}>
          Sửa lệnh
        </Button>
        <div className="flex justify-between">
          <div className="flex items-center gap-[4px]">
            <span className="text-sm">Giá trị tạm tính</span>
          </div>
          <div className="text-sm">
            {orderType === 'LO'
              ? Math.floor(Number(price || 0) * 1000 * (quanlity || 0)).toLocaleString('en-US')
              : Math.floor((stockData?.last_price || 0) * (quanlity || 0)).toLocaleString('en-US')}
          </div>
        </div>
      </div>
      <OrderModal
        isOpen={showPIN}
        loading={loading}
        title={'Xác nhận sửa lệnh'}
        stockInfo={{
          action: order.bid_ask_type,
          symbol: order.stock_symbol,
          price: orderType === 'LO' ? price : orderType,
          quanlity: quanlity || 0,
        }}
        handleGetCertInfo={data => {
          setCertInfo(data);
        }}
        certInfo={certInfo}
        closeModal={() => {
          setShowPIN(false);
          setTimeout(() => {
            setCertInfo(null);
          }, 300);
        }}
        onSubmit={(pin, type) => {
          if (!price && orderType === 'LO') {
            toast('Giá đặt không hợp lệ.', {
              type: 'error',
              icon: <RiErrorWarningLine className="text-[#F04438]" />,
            });
            return;
          }
          if (!quanlity) {
            toast('Khối lượng không hợp lệ.', {
              type: 'error',
              icon: <RiErrorWarningLine className="text-[#F04438]" />,
            });
            return;
          }
          updateOrder(pin, type, true);
        }}
      />
    </>
  );
};
