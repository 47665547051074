import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from 'core/types';

const menuStyle =
  'h-[70px] w-[75px] flex flex-col gap-[6px] items-center justify-center text-icon_gray text-center cursor-pointer text-xs font-normal leading-4';

const activeMenuStyle = 'text-primary font-semibold';

const activeSubmenuStyle = 'bg-tab_background text-primary';

const submenuStyle = 'flex items-center gap-2 py-2 px-4';

const SubMenuItem = ({ title, isSubmenu, icon, activeIcon, url, isActive, onClick }: MenuItem) => {
  const handleOnClick = () => {
    onClick && onClick();
  };
  const renderChild = () => (
    <>
      <span className="text-2xl">{isActive ? activeIcon || icon : icon}</span>
      <span className="flex-1 whitespace-nowrap">{title}</span>
    </>
  );
  return url ? (
    <Link
      to={url}
      className={`${isSubmenu ? submenuStyle : menuStyle} ${
        isActive ? (isSubmenu ? activeSubmenuStyle : activeMenuStyle) : ''
      }`}
      onClick={handleOnClick}
    >
      {renderChild()}
    </Link>
  ) : (
    <div
      className={`${isSubmenu ? submenuStyle : menuStyle} ${
        isActive ? (isSubmenu ? activeSubmenuStyle : activeMenuStyle) : ''
      }`}
      onClick={handleOnClick}
    >
      {renderChild()}
    </div>
  );
};

export default SubMenuItem;
