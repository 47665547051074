import { SelectIcon } from 'assets/icons';
import { AccountService, ConfigService } from 'core';
import React, { useEffect, useState } from 'react';

const StockNomalCommandData = [
  {
    id: 1,
    stockCode: 'MP LO',
    stockName: 'Lệnh thường',
    description: 'MTA_ORDER_DESCRIPTION_NORMAL'
  }
];

const StockConditionCommandData = [
  {
    id: 1,
    stockCode: 'GTD',
    stockName: 'GTD (Lệnh điều kiện thời gian)',
    description: 'MTA_ORDER_DESCRIPTION_GTD'
  },
  {
    id: 2,
    stockCode: 'STP',
    stockName: 'Stop (Lệnh thị trường dừng)',
    description: 'MTA_ORDER_DESCRIPTION_STOP'
  },
  {
    id: 3,
    stockCode: 'STL',
    stockName: 'Stop Limit (Lệnh giới hạn dừng)',
    description: 'MTA_ORDER_DESCRIPTION_STOP_LIMIT'
  },
  {
    id: 4,
    stockCode: 'TS',
    stockName: 'Trailing Stop (Lệnh thị trường xu hướng)',
    description: 'MTA_ORDER_DESCRIPTION_TRAILING_STOP'
  },
  {
    id: 5,
    stockCode: 'TSL',
    stockName: 'Trailing Stop Limit (Lệnh giới hạn xu hướng)',
    description: 'MTA_ORDER_DESCRIPTION_TRAILING_STOP_LIMIT'
  },
  {
    id: 6,
    stockCode: 'OCO',
    stockName: 'One Cancels Other',
    description: 'MTA_ORDER_DESCRIPTION_ONE_CANCELS_OTHER'
  },
  {
    id: 7,
    stockCode: 'SL TP',
    stockName: 'Stop Loss/Take Profit (Chốt lời/Cắt lỗ)',
    description: 'MTA_ORDER_DESCRIPTION_STOPLOSS_TAKEPROFIT'
  }
];

const StockSelectCommandOptions = ({
  onSelectItem
}: {
  onSelectItem: () => void;
}) => {
  const [metadata, setMetadata] = useState<any>();

  const getMetadata = async () => {
    const data = await ConfigService.getMetaData();
    setMetadata(data);
  };

  useEffect(() => {
    getMetadata();
  }, []);

  const getDescription = (key: string) => {
    return metadata?.[key];
  };

  return (
    <div className="p-4 border-style rounded-md absolute top-[42px] w-full bg-black z-10 border">
      <div className="flex flex-col gap-2">
        <p className="text-xs font-normal leading-[18px] tracking-[-0.6px] text-white">
          Lệnh cơ bản
        </p>
        {StockNomalCommandData.map(item => (
          <div
            className="p-2 flex gap-2 relative text-gray isSelectStyle rounded-md cursor-pointer"
            key={item.id}
            onClick={() => onSelectItem()}
          >
            <div className="min-w-[40px] h-10 rounded shadow-Shadow/xs flex justify-center items-center bg-blackBlue command-code">
              <p className="text-text_main_light text-sm font-semibold leading-4 text-center">
                {item.stockCode}
              </p>
            </div>
            <div className="flex flex-col gap-1 ">
              <p className="text-sm font-semibold leading-5">
                {item.stockName}
              </p>
              <p className="line-clamp-2 text-xs font-normal leading-[18px]">
                {getDescription(item.description)}
              </p>
            </div>
            <div className="absolute right-2 top-2">
              <SelectIcon />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4 flex flex-col gap-2">
        <p className="text-xs font-normal leading-[18px] tracking-[-0.6px] text-white">
          Lệnh điều kiện
        </p>
        {StockConditionCommandData.map(item => (
          <div
            className="p-2 flex gap-2 relative text-gray rounded-md cursor-pointer"
            key={item.id}
          >
            <div className="min-w-[40px] h-10 rounded shadow-Shadow/xs flex justify-center items-center bg-blackBlue">
              <p className="text-text_main_light text-sm font-semibold leading-4 text-center">
                {item.stockCode}
              </p>
            </div>
            <div className="flex flex-col gap-1 text-text_main_med">
              <p className="text-sm font-semibold leading-5">
                {item.stockName}
              </p>
              <p className="line-clamp-2 text-xs font-normal leading-[18px]">
                {getDescription(item.description)}
              </p>
            </div>
            {/* <div className="absolute right-2 top-2">
              <SelectIcon />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StockSelectCommandOptions;
