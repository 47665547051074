import { ArrowDownBig, InfomationIcon } from 'assets/icons';
import React, { useState, useEffect } from 'react';
import useComponentVisible from 'core/hooks/UseComponentVisible';
import { BASIC_ORDERS, CONDITION_ORDERS } from 'core/constant';
import StockItem from 'components/StockItem';
import { RiInformationLine, RiCloseFill } from 'react-icons/ri';
import { ConfigService } from 'core';

const LIST_ORDERS = [...BASIC_ORDERS, ...CONDITION_ORDERS];

const OrderTypeInfo = ({ disabled }: { disabled?: boolean }) => {
  const [order, setOrder] = useState(LIST_ORDERS[0]);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const [metadata, setMetadata] = useState<any>();

  const getMetadata = async () => {
    const data = await ConfigService.getMetaData();
    setMetadata(data);
  };

  useEffect(() => {
    getMetadata();
  }, []);

  const getDescription = (key: string) => {
    return metadata?.[key];
  };

  return (
    <div ref={ref}>
      <button
        className="bg-tab_background rounded-l-md h-full w-10 flex items-center justify-center"
        onClick={() => setIsComponentVisible(true)}
      >
        <RiInformationLine className="text-text_main_light text-[21px]" />
      </button>
      {isComponentVisible && (
        <div className="p-4 border-style rounded-md absolute top-[42px] w-full bg-black z-10 border">
          <div className="flex justify-between items-center mb-4">
            <div className="text-sm font-semibold">Thông tin lệnh</div>
            <button onClick={() => setIsComponentVisible(false)}>
              <RiCloseFill />
            </button>
          </div>
          <div className="relative overflow-auto scrollbar-horizontal pb-1 mb-3">
            <div className="flex gap-2 flex-nowrap">
              {LIST_ORDERS.map(i => (
                <StockItem
                  key={i.code}
                  isActived={order.code === i.code}
                  handleClickStock={() => {
                    setOrder(i);
                  }}
                >
                  {i.nameCode}
                </StockItem>
              ))}
            </div>
          </div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: getDescription(order.tooltip)
                .replaceAll('**:', ': </strong>')
                .replaceAll(':**', ': </strong>')
                .replaceAll('**', '<strong>')
                .replace(/\n/g, '<br>')
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default OrderTypeInfo;
