import { SearchIcon } from 'assets/icons';
import React, { useEffect, useRef, useState } from 'react';
import StockOptionSelect from './StockOptionSelect';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useComponentVisible from 'core/hooks/UseComponentVisible';
import { ACTION_ORDER, ACTION_ORDER_TYPE } from 'core/constant';

type Props = {
  type: ACTION_ORDER_TYPE;
  isActive: boolean;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
};

export const StockOrderButton = ({
  type,
  isActive,
  className,
  disabled,
  onClick
}: Props) => {
  return (
    <button
      className={`relative text-center p-[10px] cursor-pointer text-sm font-semibold order-button order-button--${
        ACTION_ORDER[type].value
      } ${className ? className : ''} ${isActive ? 'active' : ''}`}
      disabled={disabled}
      onClick={() => onClick()}
    >
      <span
        className={`relative ${isActive ? 'text-white' : 'text-text_main_light'}`}
      >
        {ACTION_ORDER[type].label}
      </span>
    </button>
  );
};
