import React, { useEffect, useState } from 'react';
import { PasswordVisibilityToggle } from 'components/PasswordVisibilityToggle';
import { Button } from 'components/buttons/Button';
import { Modal } from 'components/modal/Modal';
import { AccountService } from 'core';
import { sha256 } from 'js-sha256';
import { RiCheckFill, RiCloseLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

enum changePasswordSteps {
  CURRENT_PASSWORD,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
}

const changePasswordRules = [
  {
    key: 'atLeast10Characters',
    rule: 'Ít nhất 10 ký tự',
    isPassed: false,
  },
  {
    key: 'atLeast1Number',
    rule: 'Ít nhất 1 ký tự chữ số',
    isPassed: false,
  },
  {
    key: 'atLeast1LowerCase',
    rule: 'Ít nhất 1 ký tự chữ cái viết thường',
    isPassed: false,
  },
  {
    key: 'atLeast1UpperCase',
    rule: 'Ít nhất 1 ký tự chữ cái viết hoa',
    isPassed: false,
  },
  {
    key: 'twoSamePassword',
    rule: '2 mật khẩu trùng nhau',
    isPassed: false,
  },
];

type changePasswordModelProps = {
  isShowChangePasswordModal: boolean;
  setIsShowChangePasswordModal: (isShow: boolean) => void;
};

export const ChangePasswordModel = ({
  isShowChangePasswordModal,
  setIsShowChangePasswordModal,
}: changePasswordModelProps) => {
  const navigate = useNavigate();
  const [changePasswordStep, setChangePasswordStep] = useState(
    changePasswordSteps.CURRENT_PASSWORD
  );
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const handleStepChangePassword = async () => {
    if (changePasswordStep === changePasswordSteps.CURRENT_PASSWORD) {
      if (currentPassword) {
        const params = {
          password: sha256(currentPassword),
        };
        const res = await AccountService.checkPassword(params);
        if (res?.data?.status === 'Success') {
          setError('');
          setChangePasswordStep(changePasswordSteps.CHANGE_PASSWORD);
          setIsShowPassword(false);
        } else {
          setError(res?.data?.error_message.split('|')[1]);
        }
      } else {
        setError('Vui lòng nhập mật khẩu hiện tại');
      }
    } else if (changePasswordStep === changePasswordSteps.CHANGE_PASSWORD) {
      if (!newPassword) {
        setError('Vui lòng nhập mật khẩu mới');
      } else {
        if (
          changePasswordRules.slice(0, changePasswordRules.length - 1).every(rule => rule.isPassed)
        ) {
          if (newPassword === confirmNewPassword) {
            const params = {
              password_old: sha256(currentPassword),
              password_new: newPassword,
              password_new_confirm: confirmNewPassword,
            };
            const res = await AccountService.updatePassword(params);
            if (res?.data?.status === 'Success') {
              setError('');
              setChangePasswordStep(changePasswordSteps.CHANGE_PASSWORD_SUCCESS);
            } else {
              setError(res?.data?.error_message.split('|')[1]);
            }
          } else {
            setErrorConfirmPassword('Mật khẩu không trùng khớp');
          }
        } else {
          setError('Mật khẩu không đúng định dạng');
        }
      }
    }
  };

  const clearError = () => {
    setError('');
    setErrorConfirmPassword('');
  };

  const clearInput = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  const handleCloseModal = () => {
    setIsShowChangePasswordModal(false);
    setTimeout(() => {
      clearInput();
      clearError();
      setIsShowPassword(false);
      setIsShowConfirmPassword(false);
      changePasswordRules.forEach(rule => {
        rule.isPassed = false;
      });
      setChangePasswordStep(changePasswordSteps.CURRENT_PASSWORD);
    }, 800);
  };

  const handleCheckPasswordFormat = (password: string) => {
    // Ít nhất 10 ký tự
    changePasswordRules[0].isPassed = password.length >= 10;

    // Ít nhất 1 ký tự chữ số
    changePasswordRules[1].isPassed = /[0-9]/.test(password);

    // Ít nhất 1 ký tự chữ cái viết thường
    changePasswordRules[2].isPassed = /[a-z]/.test(password);

    // Ít nhất 1 ký tự chữ cái viết hoa
    changePasswordRules[3].isPassed = /[A-Z]/.test(password);

    // 2 mật khẩu trùng nhau
    changePasswordRules[4].isPassed = password === confirmNewPassword;
  };

  const handleCheckSamePassword = (confirmPassword: string) => {
    changePasswordRules[4].isPassed = newPassword === confirmPassword;
  };

  return (
    <div>
      <Modal
        isOpen={isShowChangePasswordModal}
        title={`${
          changePasswordStep === changePasswordSteps.CHANGE_PASSWORD_SUCCESS
            ? 'Đổi mật khẩu thành công'
            : 'Đổi mật khẩu'
        }`}
        classNameTitle={`${
          changePasswordStep === changePasswordSteps.CHANGE_PASSWORD_SUCCESS
            ? '!text-xl !font-semibold !leading-[26px]'
            : ''
        }`}
        subtitle={`
        ${
          changePasswordStep === changePasswordSteps.CURRENT_PASSWORD
            ? `Vui lòng nhập mật khẩu đăng nhập để xác thực tài khoản của bạn.`
            : changePasswordStep === changePasswordSteps.CHANGE_PASSWORD
            ? 'Vui lòng nhập mật khẩu đăng nhập mới.'
            : 'Quý khách vừa đổi mật khẩu thành công. Vui lòng đăng nhập lại tài khoản'
        }
        `}
        subtitleClassName={`!text-[14px] !font-normal leading-5 ${
          changePasswordStep === changePasswordSteps.CHANGE_PASSWORD_SUCCESS &&
          'text-text_main_light !text-base !leading-[22px]'
        }`}
        onClose={() => {}}
        className={`max-w-[640px]`}
        type={`${
          changePasswordStep === changePasswordSteps.CHANGE_PASSWORD_SUCCESS ? 'success' : 'lock'
        }`}
      >
        <div className="pt-3">
          {changePasswordStep === changePasswordSteps.CURRENT_PASSWORD && (
            <div className="form-input relative">
              <label htmlFor="" className="text-sm font-normal leading-5">
                Mật khẩu hiện tại <span className="text-carminePink">*</span>
              </label>
              <input
                type={isShowPassword ? 'text' : 'password'}
                placeholder="Nhập mật khẩu hiện tại"
                className={`form-input__input ${error && 'form-input__input_error'}`}
                onChange={e => {
                  setCurrentPassword(e.target.value);
                  setError('');
                }}
                value={currentPassword}
              ></input>
              <PasswordVisibilityToggle
                toggleVisibility={() => {
                  setIsShowPassword(!isShowPassword);
                }}
                visible={isShowPassword}
              />
              {error && (
                <div className="mt-2">
                  <p className="text-sm font-normal leading-5 text-carminePink">{error}</p>
                </div>
              )}
            </div>
          )}
          {changePasswordStep === changePasswordSteps.CHANGE_PASSWORD && (
            <>
              <div className="form-input relative">
                <label htmlFor="" className="text-sm font-normal leading-5">
                  Mật khẩu mới <span className="text-carminePink">*</span>
                </label>
                <input
                  type={isShowPassword ? 'text' : 'password'}
                  placeholder="Nhập mật khẩu mới"
                  className={`form-input__input ${error && 'form-input__input_error'}`}
                  onChange={e => {
                    setError('');
                    setNewPassword(e.target.value);
                    handleCheckPasswordFormat(e.target.value);
                  }}
                  value={newPassword}
                ></input>
                <PasswordVisibilityToggle
                  toggleVisibility={() => {
                    setIsShowPassword(!isShowPassword);
                  }}
                  visible={isShowPassword}
                />
                {error && (
                  <div className="mt-[2px]">
                    <p className="text-sm font-normal leading-5 text-carminePink">{error}</p>
                  </div>
                )}
              </div>
              <div className="form-input mt-2 relative">
                <label htmlFor="" className="text-sm font-normal leading-5">
                  Xác nhận mật khẩu mới <span className="text-carminePink">*</span>
                </label>
                <input
                  type={isShowConfirmPassword ? 'text' : 'password'}
                  placeholder="Xác nhận mật khẩu mới"
                  className={`form-input__input ${
                    errorConfirmPassword && 'form-input__input_error'
                  }`}
                  onChange={e => {
                    setErrorConfirmPassword('');
                    setConfirmNewPassword(e.target.value);
                    handleCheckSamePassword(e.target.value);
                  }}
                  value={confirmNewPassword}
                ></input>
                <PasswordVisibilityToggle
                  toggleVisibility={() => {
                    setIsShowConfirmPassword(!isShowConfirmPassword);
                  }}
                  visible={isShowConfirmPassword}
                />
                {errorConfirmPassword && (
                  <div className="mt-[2px]">
                    <p className="text-sm font-normal leading-5 text-carminePink">
                      {errorConfirmPassword}
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-2 flex flex-col gap-1 text-sm font-medium leading-5">
                <p>Mật khẩu đạt chuẩn phải có:</p>
                {changePasswordRules.map(rule => (
                  <div className="flex items-center gap-2" key={rule.key}>
                    {newPassword || confirmNewPassword ? (
                      rule.isPassed ? (
                        <RiCheckFill className="text-base text-green" />
                      ) : (
                        <RiCloseLine className="text-base text-error" />
                      )
                    ) : (
                      <RiCheckFill className="text-base text-text_main_light" />
                    )}

                    <p>{rule.rule}</p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="flex items-center gap-3 mt-[64px]">
          {changePasswordStep !== changePasswordSteps.CHANGE_PASSWORD_SUCCESS && (
            <Button
              className="flex-1 !text-primary font-semibold !bg-black_blue"
              variant="secondary"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Đóng
            </Button>
          )}

          {changePasswordStep === changePasswordSteps.CHANGE_PASSWORD_SUCCESS ? (
            <Button
              variant="primary"
              className="flex-1"
              onClick={() => {
                navigate('/logout');
              }}
            >
              Đăng nhập lại
            </Button>
          ) : (
            <Button variant="primary" className="flex-1" onClick={handleStepChangePassword}>
              Tiếp theo
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};
