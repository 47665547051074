import React, { useEffect, useState } from 'react';
import { PieChartItem, AssetDistributionItem } from 'core/types';
import { formatCurrency } from 'core/utils';
import { PieChart } from 'components';
import { useAccount } from 'core/contexts/AccountContext';
import { AssetsService } from 'core/services';

const COLOR = ['#006E01', '#00C802', '#A9EDA9', '#C0C4C0', '#DBFFDB'];

const AssetsPieChart = () => {
  const { account } = useAccount();
  const [dataChart, setDataChart] = useState<(PieChartItem & AssetDistributionItem)[]>([]);

  const fetchTotalAssetsDistribution = async () => {
    if (account) {
      const res = await AssetsService.getTotalAssetsDistribution(account.account_id);
      const data = res?.data?.data;
      const dataFormat = data?.map((i: AssetDistributionItem, idx: number) => {
        return {
          ...i,
          color:
            i.key === 'CashAmount'
              ? COLOR[4]
              : data.length === 2
              ? COLOR[2]
              : data.length === 3
              ? COLOR[idx + 1]
              : COLOR[idx],
          y: i.percent,
          name: i.name,
        };
      });
      setDataChart(dataFormat);
    }
  };

  useEffect(() => {
    fetchTotalAssetsDistribution();
  }, [account]);

  return (
    <>
      <PieChart data={dataChart} />
      <div className="flex-1">
        <ul className="flex flex-col gap-4">
          {dataChart.map((i, idx) => (
            <li key={`chart-desc-${i?.key}`} className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <div className="w-2 h-2" style={{ backgroundColor: i.color }}></div>
                <span className="text-sm">
                  {i.name} ({i.percent})%
                </span>
              </div>
              <div className="text-sm">{formatCurrency(i?.amount)}</div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default AssetsPieChart;
