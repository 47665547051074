import React, { ReactNode } from 'react';

type Props = {
  className?: string;
  type?: any;
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'white' | 'orange-warning' | 'green' | 'red' | 'blue';
};

export const Button = ({
  className,
  type = 'button',
  size = 'medium',
  disabled,
  children,
  variant = 'primary',
  onClick,
}: Props) => {
  const variantStyle =
    variant === 'primary'
      ? 'bg-primary hover:bg-[#FFF] hover:text-primary'
      : variant === 'secondary'
      ? 'bg-tab_background !text-primary border border-solid border-primary'
      : variant === 'green'
      ? 'bg-green hover:bg-green'
      : variant === 'red'
      ? 'bg-carminePink'
      : variant === 'blue'
      ? 'bg-floor_color'
      : '!bg-black';

  const sizeStyle =
    size === 'small'
      ? 'text-xs px-4 py-2'
      : size === 'large'
      ? 'px-[18px] py-[10px] text-base'
      : 'px-[18px] py-[10px] text-sm';
  return (
    <>
      <button
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={`
          flex items-center justify-center gap-2 font-semibold text-white transition rounded-[4px] max-h-10 
          disabled:cursor-not-allowed disabled:text-text_main_light disabled:bg-black focus:none shadow-Shadow/xs
          ${variantStyle}
          ${sizeStyle}
          ${className}
        `}
      >
        {children}
      </button>
    </>
  );
};
