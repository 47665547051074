import React, { Fragment, useState, ReactNode } from 'react';
import { VndBoxIcon } from 'assets/icons/index';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {
  RiAccountBoxFill,
  RiAccountBoxLine,
  RiAccountCircleFill,
  RiCoinsFill,
  RiExchangeFill,
  RiFileListFill,
  RiLockPasswordFill,
  RiMailFill,
  RiMailLockFill,
  RiMoneyDollarCircleFill,
  RiPhoneFill,
  RiTimeFill,
  RiShieldKeyholeFill,
  RiSettings4Line,
  RiSettings4Fill,
  RiStockLine,
  RiStockFill,
  RiAuctionLine,
  RiAuctionFill,
  RiWallet3Line,
  RiWallet3Fill,
} from 'react-icons/ri';
import {
  ChangePasswordModel,
  EmailSupportModel,
  RequestCompleteAccountModal,
} from 'components/index';
import ChangePinModel from 'components/changePinModel/ChangePinModel';
import { PhoneSupportModel } from 'components/index';
import { AiFillCreditCard, AiFillPieChart } from 'react-icons/ai';
import { useAccount } from 'core/contexts';
import { MenuItem as MenuItemType } from 'core/types';
import MenuItem from './MenuItem';

const checkIsActiveMenu = (path: string, menu: any) => {
  if (path === menu?.url) return true;
  if (menu?.submenuUrl && menu?.submenuUrl.includes(path)) {
    return true;
  }
};

const SideBar = () => {
  const pathName = useLocation().pathname;
  const [isShowChangePasswordModal, setIsShowChangePasswordModal] = useState(false);
  const [isShowCompleteAccountModal, setIsShowCompleteAccountModal] = useState(false);
  const [isShowChangePinModal, setIsShowChangePinModal] = useState(false);
  const [isShowPhoneSupportModal, setIsShowPhoneSupportModal] = useState(false);
  const [isShowEmailSupportModal, setIsShowEmailSupportModal] = useState(false);
  const { account } = useAccount();

  const menu: MenuItemType[] = [
    {
      title: 'Thị trường',
      url: '/market',
      icon: <RiStockLine />,
      activeIcon: <RiStockFill />,
    },
    {
      title: 'Đặt lệnh',
      url: '/stock-order',
      icon: <RiAuctionLine />,
      activeIcon: <RiAuctionFill />,
    },
    {
      title: 'Danh mục',
      url: '/',
      icon: <RiWallet3Line />,
      activeIcon: <RiWallet3Fill />,
    },
    {
      title: 'Tiền',
      icon: <VndBoxIcon />,
      activeIcon: <VndBoxIcon active />,
      submenuUrl: [
        '/cash',
        '/deposit',
        '/withdraw',
        '/transfer-cash',
        '/advance-cash',
        '/cash-history',
      ],
      submenu: [
        {
          title: 'Tổng quan',
          icon: <AiFillPieChart />,
          url: '/cash',
        },
        {
          title: 'Nạp tiền',
          icon: <AiFillCreditCard />,
          url: '/deposit',
        },
        {
          title: 'Rút tiền',
          icon: <RiCoinsFill />,
          url: '/withdraw',
        },
        {
          title: 'Chuyển tiền',
          icon: <RiExchangeFill />,
          url: '/transfer-cash',
        },
        {
          title: 'Ứng tiền',
          icon: <RiMoneyDollarCircleFill />,
          url: '/advance-cash',
        },
        {
          title: 'Sao kê tiền',
          icon: <RiFileListFill />,
          url: '/cash-history',
        },
      ],
    },
    {
      title: 'Tài khoản',
      icon: <RiAccountBoxLine />,
      activeIcon: <RiAccountBoxFill />,
      submenuUrl: ['/account', '/order-history'],
      submenu: [
        {
          title: 'Thông tin tài khoản',
          icon: <RiAccountCircleFill />,
          url: '/account',
        },
        {
          title: 'Lịch sử lệnh',
          icon: <RiTimeFill />,
          url: '/order-history',
        },
      ],
    },
    {
      title: 'Cài đặt',
      icon: <RiSettings4Line />,
      activeIcon: <RiSettings4Fill />,
      submenuUrl: ['/two-factor-authentication'],
      submenu: [
        {
          title: 'Đổi mật khẩu đăng nhập',
          icon: <RiLockPasswordFill />,
          onClick: () => {
            setIsShowChangePasswordModal(true);
          },
        },
        {
          title: 'Đổi mã PIN',
          icon: <RiMailLockFill />,
          onClick: () => {
            account ? setIsShowChangePinModal(true) : setIsShowCompleteAccountModal(true);
          },
        },
        {
          title: 'Đổi xác thực 2 yếu tố',
          icon: <RiShieldKeyholeFill />,
          url: '/two-factor-authentication',
        },
        {
          title: 'Hỗ trợ qua điện thoại',
          icon: <RiPhoneFill />,
          onClick: () => {
            setIsShowPhoneSupportModal(true);
          },
        },
        {
          title: 'Hỗ trợ qua email',
          icon: <RiMailFill />,
          onClick: () => {
            setIsShowEmailSupportModal(true);
          },
        },
      ],
    },
  ];

  return (
    <div className="bg-main_background_white fixed left-0 top-[70px] bottom-[28px] pt-4 pb-10 z-50">
      <div className="max-h-full overflow-y-auto">
        {menu.map((i, index) => {
          return i.submenu && i.submenu?.length > 0 ? (
            <Menu as="div">
              <Menu.Button>
                <MenuItem
                  key={`menu-item-${index}`}
                  title={i.title}
                  icon={i?.icon}
                  activeIcon={i?.activeIcon}
                  isActive={checkIsActiveMenu(pathName, i)}
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute text-base left-[75px] flex flex-col gap-2 z-[90] top-[220px] min-w-[192px] bg-black py-4 text-text_main_dark border border-solid border-tab_background font-semibold leading-[22px]">
                  {i.submenu.map((j, idx) => (
                    <MenuItem
                      key={`sub-menu-item-${index}-${idx}`}
                      isSubmenu
                      title={j.title}
                      url={j?.url}
                      icon={j?.icon}
                      activeIcon={j?.activeIcon}
                      isActive={checkIsActiveMenu(pathName, j)}
                      onClick={() => {
                        j.onClick && j.onClick();
                      }}
                    />
                  ))}
                </div>
              </Transition>
            </Menu>
          ) : (
            <MenuItem
              key={`menu-item-${index}`}
              title={i.title}
              url={i?.url}
              icon={i?.icon}
              activeIcon={i?.activeIcon}
              onClick={() => {
                i.onClick && i.onClick();
              }}
              isActive={checkIsActiveMenu(pathName, i)}
            />
          );
        })}
      </div>
      <ChangePasswordModel
        isShowChangePasswordModal={isShowChangePasswordModal}
        setIsShowChangePasswordModal={setIsShowChangePasswordModal}
      />
      <ChangePinModel
        isShowChangePINModal={isShowChangePinModal}
        setIsShowChangePINModal={setIsShowChangePinModal}
      />
      <RequestCompleteAccountModal
        isOpen={isShowCompleteAccountModal}
        closeModal={() => {
          setIsShowCompleteAccountModal(false);
        }}
      />
      <PhoneSupportModel
        isShowPhoneSupportModal={isShowPhoneSupportModal}
        setIsShowPhoneSupportModal={setIsShowPhoneSupportModal}
      />
      <EmailSupportModel
        isShowEmailSupportModal={isShowEmailSupportModal}
        setIsShowEmailSupportModal={setIsShowEmailSupportModal}
      />
    </div>
  );
};

export default SideBar;
