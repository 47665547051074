import React, { useEffect, useState } from 'react';
import { NoDataIcon } from 'assets/icons';
import { RecommendationsCircle } from './components/RecommendationsCircle';
import { ProgressBar } from './components/ProgressBar';
import { TabItem } from './components/TabItem';
import LiquidityChart from './components/LiquidityChart';
import { BarChart } from 'components/charts/BarChart';
import { MarketStockService } from 'core';
import { SpinnerIcon } from 'components/spinner/SpinnerIcon';
import { ValuationChart } from './components/ValuationChart';
import { AnalysisItem } from 'core/types/Analysis.type';
import { ChartData, LiquityChartItem } from 'core/types';
import { ComingSoonFeature, TitleSection } from 'components';

enum LiquidityTab {
  GTGD,
  KLGD,
  BUY,
  SELL,
}

type DetailStockAnalysisProps = {
  stockCode: string;
  lastPrice: number;
};

export const DetailStockAnalysis = ({ stockCode, lastPrice }: DetailStockAnalysisProps) => {
  const [liquidityTab, setLiquidityTab] = useState(LiquidityTab.GTGD);
  const [analysData, setAnalysData] = useState<AnalysisItem>();
  const [loading, setLoading] = useState(true);
  const [liquidityData, setLiquidityData] = useState<LiquityChartItem[]>([]);
  const [businessPerformancesData, setBusinessPerformancesData] = useState<ChartData[]>(); // Kết quả kinh doanh
  const [businessPerformancesChartData, setBusinessPerformancesChartData] = useState<any>([]); // Kết quả kinh doanh
  const [balanceSheetsData, setBalanceSheetsData] = useState<ChartData[]>(); // Cân đối kế toán
  const [balanceSheetsChartData, setBalanceSheetsChartData] = useState<any>(); // Cân đối kế toán

  const fetchData = async () => {
    setLoading(true);
    const params = {
      symbol: stockCode,
    };

    const res = await MarketStockService.getStockAnalysis(params);
    if (res?.data?.status === 'Success') {
      setAnalysData(res?.data?.data);
      const businessPerformancesData = (res?.data?.data?.business_performances)
        .sort((a: ChartData, b: ChartData) => {
          return a?.year_report - b?.year_report;
        })
        .filter((item: ChartData) => item.length_report === 5)
        .slice(-5);
      setBusinessPerformancesData(businessPerformancesData);
      const dataChartBusinessPerforman = [
        {
          name: 'Doanh thu',
          data: businessPerformancesData?.map((item: ChartData) => {
            return {
              y: item?.revenue,
              color: '#0BA5EC',
            };
          }),
        },
        {
          name: 'Lợi nhuận',
          data: businessPerformancesData?.map((item: ChartData) => {
            return {
              y: item?.profit,
              color: '#00C802',
            };
          }),
        },
      ];

      setBusinessPerformancesChartData(dataChartBusinessPerforman);
      const balanceSheetsData = res?.data?.data?.balance_sheets
        ?.sort((a: ChartData, b: ChartData) => {
          return a?.year_report - b?.year_report;
        })
        ?.filter((item: ChartData) => item.length_report === 5)
        ?.slice(-5);
      setBalanceSheetsData(balanceSheetsData);
      const dataChartBalanceSheets = [
        {
          name: 'Tổng tài sản',
          data: balanceSheetsData?.map((item: ChartData) => {
            return {
              y: item?.asset,
              color: '#0BA5EC',
            };
          }),
        },
        {
          name: 'Vốn chủ sở hữu',
          data: balanceSheetsData?.map((item: ChartData) => {
            return {
              y: item?.equity,
              color: '#00C802',
            };
          }),
        },
      ];

      setBalanceSheetsChartData(dataChartBalanceSheets);
    }
    setLoading(false);
  };

  const checkTypeRecommendations = (
    buyPercent: number,
    holdPercent: number,
    sellPercent: number
  ) => {
    const maxPercent = Math.max(buyPercent, holdPercent, sellPercent);
    if (maxPercent === buyPercent) {
      return 'BUY';
    }
    if (maxPercent === holdPercent) {
      return 'HOLD';
    }
    if (maxPercent === sellPercent) {
      return 'SELL';
    }
    if (buyPercent === holdPercent) {
      return 'BUY';
    }
    if (buyPercent === sellPercent) {
      return 'BUY';
    }
    if (sellPercent === holdPercent) {
      return 'SELL';
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let liquidity = {
      avg_month: 0,
      avg_week: 0,
      yesterday: 0,
      today: 0,
    } as any;
    if (liquidityTab === LiquidityTab.GTGD) {
      liquidity = analysData?.value_liquidity;
    }
    if (liquidityTab === LiquidityTab.KLGD) {
      liquidity = analysData?.vol_liquidity;
    }
    if (liquidityTab === LiquidityTab.BUY) {
      liquidity = analysData?.foreign_buy;
    }
    if (liquidityTab === LiquidityTab.SELL) {
      liquidity = analysData?.foreign_sell;
    }
    const data = [
      {
        type: 'MONTH',
        price: liquidity?.avg_month,
      },
      {
        type: 'WEEK',
        price: liquidity?.avg_week,
      },
      {
        type: 'LAST_DAY',
        price: liquidity?.yesterday,
      },
      {
        type: 'TO_DAY',
        price: liquidity?.today,
      },
    ];
    setLiquidityData(data);
  }, [liquidityTab, analysData]);

  return (
    <div className="flex gap-2">
      <div className="w-1/3 flex flex-col gap-2 h-fit">
        {analysData?.recommendation && (
          <div className="flex flex-col gap-4 bg-black border-style !border-t-0 rounded-lg">
            <TitleSection title="Khuyến nghị đầu tư" />
            <div className="flex justify-around gap-10 px-[56px]">
              <RecommendationsCircle
                type={checkTypeRecommendations(
                  analysData?.recommendation?.bid_percent,
                  analysData?.recommendation?.hold_percent,
                  analysData?.recommendation?.ask_percent
                )}
              />
              <div className="flex flex-col gap-3">
                <ProgressBar type="BUY" percent={analysData?.recommendation?.bid_percent} />
                <ProgressBar type="HOLD" percent={analysData?.recommendation?.hold_percent} />
                <ProgressBar type="SELL" percent={analysData?.recommendation?.ask_percent} />
              </div>
            </div>
            <div className="px-4 pb-[42px] flex flex-col gap-4 text-sm font-normal leading-5">
              <p className="font-semibold">Định giá</p>
              <ValuationChart
                highPrice={analysData?.recommendation?.high_price}
                averagePrice={analysData?.recommendation?.mid_price}
                lowPrice={analysData?.recommendation?.low_price}
                lastPrice={lastPrice}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col gap-4 bg-black border-style !border-t-0 rounded-lg">
          <TitleSection title="Thanh khoản" />
          <div className="flex flex-col gap-4 px-4 py-1 pb-4">
            <div className="flex gap-2">
              <TabItem
                title="GTGD"
                handleClick={() => {
                  setLiquidityTab(LiquidityTab.GTGD);
                }}
                isActive={liquidityTab === LiquidityTab.GTGD}
              />
              <TabItem
                title="KLGD"
                handleClick={() => {
                  setLiquidityTab(LiquidityTab.KLGD);
                }}
                isActive={liquidityTab === LiquidityTab.KLGD}
              />
              <TabItem
                title="NN mua"
                handleClick={() => {
                  setLiquidityTab(LiquidityTab.BUY);
                }}
                isActive={liquidityTab === LiquidityTab.BUY}
              />
              <TabItem
                title="NN bán"
                handleClick={() => {
                  setLiquidityTab(LiquidityTab.SELL);
                }}
                isActive={liquidityTab === LiquidityTab.SELL}
              />
            </div>
            <div>
              {loading ? (
                <div className="flex justify-center items-center">
                  <SpinnerIcon />
                </div>
              ) : (
                <LiquidityChart data={liquidityData} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/3 rounded-lg flex flex-col gap-2">
        <div className="bg-black border-style !border-t-0 rounded-lg">
          <TitleSection title="Kết quả kinh doanh" />
          {loading ? (
            <div className="flex justify-center items-center">
              <SpinnerIcon />
            </div>
          ) : businessPerformancesData ? (
            <div className="p-4">
              <div className="flex flex-col gap-3 text-xs font-normal leading-[18px] tracking-[-0.6px] text-text_main_light">
                <div>
                  <BarChart
                    data={businessPerformancesData}
                    groupData={businessPerformancesChartData}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4 items-center justify-center my-10">
              <NoDataIcon />
              <p className="text-base font-normal leading-[22px] text-text_main_med">Chưa có dữ liệu</p>
            </div>
          )}
        </div>
        <div className="bg-black border-style rounded-lg">
          <div className="border-bottom-style flex justify-between px-4 py-2">
            <p className="text-base font-semibold leading-[22px]">Cân đối kế toán</p>
            {/* <div className="flex gap-2 items-center">
            <p className="text-sm font-normal leading-5">Xem thêm</p>
            <ArrowRight />
          </div> */}
          </div>
          {loading ? (
            <div className="flex justify-center items-center">
              <SpinnerIcon />
            </div>
          ) : balanceSheetsData ? (
            <div className="p-4 flex flex-col">
              <div className="flex flex-col gap-3 text-xs font-normal leading-[18px] tracking-[-0.6px] text-text_main_light">
                <div>
                  <BarChart data={balanceSheetsData} groupData={balanceSheetsChartData} />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4 items-center justify-center my-10">
              <NoDataIcon />
              <p className="text-base font-normal leading-[22px] text-text_main_med">Chưa có dữ liệu</p>
            </div>
          )}
        </div>
      </div>
      <div className="border-style bg-black w-1/3 rounded-lg">
        <div className="border-bottom-style px-4 py-2">
          <p className="text-base font-semibold leading-[22px]">Doanh nghiệp cùng ngành</p>
        </div>
        {/* <div className="p-4 flex flex-col gap-3">
          <div className="text-sm font-normal leading-5 flex">
            <div className="pr-2 border-r-[0.5px] border-line_med">
              <p>Tài nguyên Cơ bản</p>
            </div>
            <div className="pl-2">
              <p>
                Tổng doanh nghiệp: {analysData?.number_of_industry_related || 0}
              </p>
            </div>
          </div>
          <EnterpriseTable />
        </div> */}
        <ComingSoonFeature className="justify-center w-full h-full" />
      </div>
    </div>
  );
};
