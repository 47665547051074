import React from 'react';
import { Button } from 'components/buttons/Button';
import { OrderType } from 'core/types';
import { formatStockPrice, getColorStatusOrder } from 'core/utils';
import dayjs from 'dayjs';

const smallTextStyle = 'text-xs font-normal leading-[18px] tracking-[-0.6px] text-cadetGrey mt-1';

export const StockOrderRow = ({
  order,
  onCancel,
  onUpdate,
}: {
  order: OrderType;
  onCancel: (order: OrderType) => void;
  onUpdate: (order: OrderType) => void;
}) => {
  return (
    <tr key={order.order_no} className={`align-top relative`}>
      <td>
        <div
          className={`absolute top-0 left-0 bottom-0 w-1 ${
            order.bid_ask_type === 'B'
              ? 'bg-high_price'
              : order.bid_ask_type === 'S'
              ? 'bg-reduce_color'
              : ''
          }`}
        ></div>
        <p>{order.stock_symbol}</p>
        <div className="flex gap-1">
          <p className={smallTextStyle}>
            {order.input_date ? dayjs(new Date(order.input_date)).format('DD/MM') : ''}
          </p>
          <p className={smallTextStyle}>
            {order.input_date ? dayjs(new Date(order.input_date)).format('HH:mm') : ''}
          </p>
        </div>
      </td>
      <td>
        <p
          className={`${
            order.bid_ask_type === 'B'
              ? 'text-[#00C802]'
              : order.bid_ask_type === 'S'
              ? 'text-[#F04438]'
              : ''
          }`}
        >
          {order.bid_ask_type === 'B' ? 'Mua' : order.bid_ask_type === 'S' ? 'Bán' : ''}
        </p>
        <p className={smallTextStyle}>{order.matching_type}</p>
      </td>
      <td>
        <p>{order.filled_quantity ? order.filled_quantity.toLocaleString('en-US') : '--'}</p>
        <p className={smallTextStyle}>
          {order?.order_quantity ? order.order_quantity.toLocaleString('en-US') : '-'}
        </p>
      </td>
      <td>
        <p>{order.avg_price ? formatStockPrice(order.avg_price) : '--'}</p>
        <p className={smallTextStyle}>
          {order?.order_price
            ? Number()
              ? formatStockPrice((order.order_price as number) * 1000)
              : order.order_price
            : '--'}
        </p>
      </td>
      <td>
        <p>
          {order?.filled_amount
            ? order?.commission
              ? order?.bid_ask_type === 'B'
                ? (order.filled_amount + order.commission).toLocaleString('en-US')
                : (order.filled_amount - order.commission).toLocaleString('en-US')
              : order?.filled_amount.toLocaleString('en-US')
            : '--'}
        </p>
        <p className={smallTextStyle}>
          {order.order_amount ? order.order_amount.toLocaleString('en-US') : '--'}
        </p>
      </td>
      <td className="text-right !pr-[11px]">
        <span className={`text-xs ${getColorStatusOrder(order.display_status || '')}`}>
          {order.display_status_name}
        </span>
      </td>

      <td>
        <div className="flex min-h-[42px] items-center justify-end gap-4">
          <Button
            disabled={!order.allow_edit}
            variant="red"
            className="w-[50px] h-[26px] text-xs font-bold leading-[18px] tracking-[-0.48px] border-none"
            onClick={() => {
              onCancel(order);
            }}
          >
            Hủy
          </Button>
          <Button
            disabled={!order.allow_edit}
            variant="green"
            className="w-[50px] h-[26px] text-xs font-bold leading-[18px] tracking-[-0.48px] border-none"
            onClick={() => onUpdate(order)}
          >
            Sửa
          </Button>
        </div>
      </td>
    </tr>
  );
};
