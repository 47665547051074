import React, { useState } from 'react';
import { TitleSection, StockPrice } from 'components';
import { formatValueTrading } from 'core/utils';
import { StockOrderUpdateForm } from 'components/StockOrder/StockOrderUpdateForm';
import { OrderType } from 'core/types';

export const StockOrderUpdate = ({
  stockData,
  stockInfo,
  stockBuyingPower,
  order,
  onClose,
  onSuccess,
}: {
  stockData: any;
  stockInfo: any;
  order: OrderType;
  stockBuyingPower: any;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const ask_vol1_percent =
    (stockData?.ask_vol1 / (stockData?.ask_vol1 + stockData?.ask_vol2 + stockData?.ask_vol3)) *
      100 || 0;
  const ask_vol2_percent =
    (stockData?.ask_vol2 / (stockData?.ask_vol1 + stockData?.ask_vol2 + stockData?.ask_vol3)) *
      100 || 0;
  const ask_vol3_percent =
    (stockData?.ask_vol3 / (stockData?.ask_vol1 + stockData?.ask_vol2 + stockData?.ask_vol3)) *
      100 || 0;

  const bid_vol1_percent =
    (stockData?.bid_vol1 / (stockData?.bid_vol1 + stockData?.bid_vol2 + stockData?.bid_vol3)) *
      100 || 0;
  const bid_vol2_percent =
    (stockData?.bid_vol2 / (stockData?.bid_vol1 + stockData?.bid_vol2 + stockData?.bid_vol3)) *
      100 || 0;
  const bid_vol3_percent =
    (stockData?.bid_vol3 / (stockData?.bid_vol1 + stockData?.bid_vol2 + stockData?.bid_vol3)) *
      100 || 0;

  const [price, setPrice] = useState<any>(0);

  const handleSetPrice = (price: any) => {
    setPrice(price / 1000);
  };

  return (
    <div className="w-[330px]">
      <div className="bg-black rounded-lg border-style !border-t-0">
        <TitleSection title="Sửa lệnh" onClose={onClose} />
        <div>
          <StockOrderUpdateForm
            stockBuyingPower={stockBuyingPower}
            stockData={stockData}
            stockInfo={stockInfo}
            order={order}
            onSuccess={onSuccess}
            priceStock={price}
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col gap-4 mt-2 bg-black rounded-lg border-style !border-t-0">
        <TitleSection title="Bước giá khớp lệnh" />
        <div className="px-4 pb-3">
          <div>
            <div className="text-xs font-normal text-text_main_med flex border-b py-1">
              <div className="text-left flex-1">Giá bán</div>
              <div className="text-left flex-1">KL bán</div>
              <div className="text-right flex-1">Tổng giá trị</div>
            </div>
            <div className="flex relative py-1 my-[2px]">
              <div
                className="absolute top-0 h-full left-0 bg-[#2D0600] z-[-1] rounded-tl-sm rounded-bl-sm"
                style={{ width: `${ask_vol3_percent}%` }}
              ></div>
              <div
                className="text-sm font-normal text-left flex-1 cursor-pointer"
                onClick={() => {
                  handleSetPrice(stockData?.ask_price3);
                }}
              >
                <StockPrice
                  ceiling={stockData?.ceiling || 0}
                  floor={stockData?.floor || 0}
                  last_price={stockData?.ask_price3 || 0}
                  ref_price={stockData?.ref_price || 0}
                  className="text-sm"
                />
              </div>
              <div className="text-sm font-normal text-white text-left flex-1">
                {(stockData?.ask_vol3 || 0).toLocaleString('en-US')}
              </div>
              <div className="text-sm font-normal text-white text-right flex-1">
                {formatValueTrading(stockData?.ask_price3 * stockData?.ask_vol3 || 0)}
              </div>
            </div>
            <div className="flex relative py-1 my-[2px]">
              <div
                className="absolute top-0 h-full left-0 bg-[#2D0600] z-[-1] rounded-tl-sm rounded-bl-sm"
                style={{ width: `${ask_vol2_percent}%` }}
              ></div>
              <div
                className="text-sm font-normal text-left flex-1 cursor-pointer"
                onClick={() => {
                  handleSetPrice(stockData?.ask_price2);
                }}
              >
                <StockPrice
                  ceiling={stockData?.ceiling || 0}
                  floor={stockData?.floor || 0}
                  last_price={stockData?.ask_price2 || 0}
                  ref_price={stockData?.ref_price || 0}
                  className="text-sm"
                />
              </div>
              <div className="text-sm font-normal text-white text-left flex-1">
                {(stockData?.ask_vol2 || 0).toLocaleString('en-US')}
              </div>
              <div className="text-sm font-normal text-white text-right flex-1">
                {formatValueTrading(stockData?.ask_price2 * stockData?.ask_vol2 || 0)}
              </div>
            </div>
            <div className="flex relative py-1 my-[2px]">
              <div
                className="absolute top-0 h-full left-0 bg-[#2D0600] z-[-1] rounded-tl-sm rounded-bl-sm"
                style={{ width: `${ask_vol1_percent}%` }}
              ></div>
              <div
                className="text-sm font-normal text-left flex-1 cursor-pointer"
                onClick={() => {
                  handleSetPrice(stockData?.ask_price1);
                }}
              >
                <StockPrice
                  ceiling={stockData?.ceiling || 0}
                  floor={stockData?.floor || 0}
                  last_price={stockData?.ask_price1 || 0}
                  ref_price={stockData?.ref_price || 0}
                  className="text-sm"
                />
              </div>
              <div className="text-sm font-normal text-white text-left flex-1">
                {(stockData?.ask_vol1 || 0).toLocaleString('en-US')}
              </div>
              <div className="text-sm font-normal text-white text-right flex-1">
                {formatValueTrading(stockData?.ask_price1 * stockData?.ask_vol1 || 0)}
              </div>
            </div>
          </div>
          <div
            className="text-center"
            onClick={() => {
              handleSetPrice(stockData?.last_price);
            }}
          >
            <StockPrice
              ceiling={stockData?.ceiling || 0}
              floor={stockData?.floor || 0}
              last_price={stockData?.last_price || 0}
              ref_price={stockData?.ref_price || 0}
              className="text-[28px] font-semibold"
            />
          </div>
          <div
            className={`flex text-sm justify-center mb-3 ${
              stockData?.change > 0
                ? 'text-high_price'
                : stockData?.change < 0
                ? 'text-reduce_color'
                : 'text-mustardYellow'
            }`}
          >
            <span className="pr-1">{stockData?.change ? stockData.change / 1000 : '0.00'}</span>
            <span>{stockData?.ratio_change || '+0'}%</span>
          </div>
          <div>
            <div className="text-xs font-normal text-text_main_med flex border-b py-1">
              <div className="text-left flex-1">Giá mua</div>
              <div className="text-left flex-1">KL mua</div>
              <div className="text-right flex-1">Tổng giá trị</div>
            </div>
            <div className="flex relative py-1 my-[2px]">
              <div
                className="absolute top-0 h-full left-0 bg-[#002500] z-[-1] rounded-tl-sm rounded-bl-sm"
                style={{ width: `${bid_vol1_percent}%` }}
              ></div>
              <div
                className="text-sm font-normal text-left flex-1 cursor-pointer"
                onClick={() => {
                  handleSetPrice(stockData?.bid_price1);
                }}
              >
                <StockPrice
                  ceiling={stockData?.ceiling || 0}
                  floor={stockData?.floor || 0}
                  last_price={stockData?.bid_price1 || 0}
                  ref_price={stockData?.ref_price || 0}
                  className="text-sm"
                />
              </div>
              <div className="text-sm font-normal text-white text-left flex-1">
                {(stockData?.bid_vol1 || 0).toLocaleString('en-US')}
              </div>
              <div className="text-sm font-normal text-white text-right flex-1">
                {formatValueTrading(stockData?.bid_price1 * stockData?.bid_vol1 || 0)}
              </div>
            </div>
            <div className="flex relative py-1 my-[2px]">
              <div
                className="absolute top-0 h-full left-0 bg-[#002500] z-[-1] rounded-tl-sm rounded-bl-sm"
                style={{ width: `${bid_vol2_percent}%` }}
              ></div>
              <div
                className="text-sm font-normal text-left flex-1 cursor-pointer"
                onClick={() => {
                  handleSetPrice(stockData?.bid_price2);
                }}
              >
                <StockPrice
                  ceiling={stockData?.ceiling || 0}
                  floor={stockData?.floor || 0}
                  last_price={stockData?.bid_price2 || 0}
                  ref_price={stockData?.ref_price || 0}
                  className="text-sm"
                />
              </div>
              <div className="text-sm font-normal text-white text-left flex-1">
                {(stockData?.bid_vol2 || 0).toLocaleString('en-US')}
              </div>
              <div className="text-sm font-normal text-white text-right flex-1">
                {formatValueTrading(stockData?.bid_price2 * stockData?.bid_vol2 || 0)}
              </div>
            </div>
            <div className="flex relative py-1 my-[2px]">
              <div
                className="absolute top-0 h-full left-0 bg-[#002500] z-[-1] rounded-tl-sm rounded-bl-sm"
                style={{ width: `${bid_vol3_percent}%` }}
              ></div>
              <div
                className="text-sm font-normal text-left flex-1 cursor-pointer"
                onClick={() => {
                  handleSetPrice(stockData?.bid_price3);
                }}
              >
                <StockPrice
                  ceiling={stockData?.ceiling || 0}
                  floor={stockData?.floor || 0}
                  last_price={stockData?.bid_price3 || 0}
                  ref_price={stockData?.ref_price || 0}
                  className="text-sm"
                />
              </div>
              <div className="text-sm font-normal text-white text-left flex-1">
                {(stockData?.bid_vol3 || 0).toLocaleString('en-US')}
              </div>
              <div className="text-sm font-normal text-white text-right flex-1">
                {formatValueTrading(stockData?.bid_price3 * stockData?.bid_vol3 || 0)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
