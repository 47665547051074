import React, { useEffect, useState } from 'react';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BarChartItem, BarChartType, ChartData, SeriesBarChartItem } from 'core/types';
import { formatValueTrading } from 'core/utils';

type BarChartProps = {
  data?: ChartData[];
  groupData?: BarChartType[];
};

export const BarChart = ({ data, groupData }: BarChartProps) => {
  const [groupYear, setGroupYear] = useState<any>([]);
  const [yMax, setYMax] = useState<number>(0);
  const [yMin, setYMin] = useState<number>(0);
  useEffect(() => {
    const groupYear = data?.map((item: ChartData) => item?.year_report);
    setGroupYear(groupYear);
    let max = 0;
    let min = 0;
    if (groupData) {
      groupData?.map((item: BarChartType) => {
        item?.data?.map((childItem: BarChartItem) => {
          if (childItem?.y > max) {
            max = childItem?.y;
          }
          if (childItem?.y < min) {
            min = childItem?.y;
          }
        });
      });

      setYMax(max);
      setYMin(min);
    }
  }, [data, groupData]);

  const formatDataChart = (data: BarChartType[]) => {
    const result: any = [];
    data?.map((item: BarChartType) => {
      const obj: any = {};
      obj.name = item?.name;
      obj.data = [];
      item?.data?.map((childItem: any) => {
        obj.data.push({
          y: parseFloat((childItem?.y / 1000000000).toFixed(1)),
          color: childItem?.color,
        });
      });
      result.push(obj);
    });
    return result;
  };

  const roundingNumber = (number: number) => {
    return Math.ceil(number / 10) * 10;
  };

  const gettickPositions = (min: number, max: number) => {
    const yMaxFormat = roundingNumber(parseInt((max / 1000000000 / 3).toFixed(0)));

    let yMinFormat = Math.abs(min / 1000000000);
    if (yMinFormat < yMaxFormat) {
      yMinFormat = yMaxFormat;
    } else if (yMaxFormat < yMinFormat && yMinFormat < yMaxFormat * 2) {
      yMinFormat = yMaxFormat * 2;
    } else {
      yMinFormat = yMaxFormat * 3;
    }

    if (min < 0) {
      return [-yMinFormat, 0, yMaxFormat, yMaxFormat * 2, yMaxFormat * 3];
    } else {
      return [0, yMaxFormat, yMaxFormat * 2, yMaxFormat * 3];
    }
  };

  let chartOptions: Options = {
    chart: {
      type: 'column',
      backgroundColor: '#080908',
      spacing: [12, 0, 0, 0],
      height: 181,
      style: {
        fontFamily: 'Plus Jakarta Sans',
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    // border radius column chart
    series: formatDataChart(groupData as BarChartType[])?.map((item: SeriesBarChartItem) => ({
      name: item.name,
      data: item.data,
      type: 'column',
      borderRadius: 2,
    })),
    tooltip: {
      formatter: function () {
        return '<b>' + this.x + '</b><br/>' + this.series.name + ': <b>' + this.y + ' tỷ</b>';
      },
    },
    // change color column of chart
    colors: ['#00C802', '#00C802'],

    // set width column chart
    plotOptions: {
      column: {
        pointWidth: 16,
        borderWidth: 0,
        groupPadding: 0.32,
      },
    },
    xAxis: {
      // sort groupYear from small to large
      categories: groupYear,
      lineColor: '#303630',
      lineWidth: yMin >= 0 ? 2 : 0,
      labels: {
        style: {
          color: '#98A2B3',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '18px',
          letterSpacing: '-0.6px',
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      plotLines: [
        {
          color: '#303630',
          width: 2,
          value: 0,
        },
      ],
      min: -roundingNumber(parseInt((Math.abs(yMin) / 1000000000).toFixed(0))),
      max: roundingNumber(parseInt((yMax / 1000000000).toFixed(0))),
      tickPositions: gettickPositions(yMin, yMax),
      gridLineColor: '#303630',
      gridLineDashStyle: 'Dash',
      gridLineWidth: 2,
      labels: {
        useHTML: true,
        style: {
          color: '#98A2B3',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '18px',
          letterSpacing: '-0.6px',
          'white-space': 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        formatter: function () {
          return `<div style="width: 50px; overflow: hidden; text-overflow: ellipsis;">${this.value}</div>`;
        },
      },
    },
  };

  return (
    <>
      <div className="flex gap-2 text-sm font-normal leading-5 mb-2 text-white">
        {groupData?.map((item: SeriesBarChartItem, index: number) => {
          return (
            <div className="flex gap-1 items-center" key={index}>
              <div
                className="w-2 h-2 rounded-full bg-floor_color"
                style={{ backgroundColor: item?.data?.[0]?.color }}
              ></div>
              <p>{item?.name}</p>
            </div>
          );
        })}
      </div>
      <div className="mb-3">
        <p>Đơn vị: Tỷ đồng</p>
      </div>
      {yMax > 0 && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}

      <div className="mt-3 flex flex-col gap-1">
        {groupData?.map((item: SeriesBarChartItem, index: number) => {
          return (
            <div className="flex" key={index}>
              <div className="flex flex-1">
                <div
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: item?.data?.[0]?.color }}
                ></div>
              </div>
              <div className="flex justify-between pl-[60px] pr-[34px] text-xs font-normal leading-[18px] tracking-[-0.6px] w-[93.22%]">
                {item?.data.map((childItem: BarChartItem, index: number) => {
                  return (
                    <div key={index} className="min-w-[38px] flex justify-center">
                      <p>
                        {childItem?.y < 0 ? '-' : ''}
                        {formatValueTrading(
                          parseFloat((Math.abs(childItem?.y) / 1000000000).toFixed(1))
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
