import React from 'react';
type StockItemProps = {
  children?: any;
  className?: string;
  isActived?: boolean;
  handleClickStock?: () => void;
};

const StockItem = ({
  children,
  className,
  isActived,
  handleClickStock
}: StockItemProps) => {
  return (
    <div className="flex-none">
      <div
        className={`px-2 py-1 rounded h-7 flex justify-center items-center cursor-pointer whitespace-nowrap text-[10px] lg:text-xs xl:text-sm font-semibold leading-5 ${
          isActived ? 'bg-primary text-[#fff]' : 'bg-stroke_default text-text_main_light'
        } ${className}`}
        onClick={handleClickStock}
      >
        {children}
      </div>
    </div>
  );
};

export default StockItem;
