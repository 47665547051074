import { BANK } from '../constant/api.constant';
import axiosInstance, { axiosInstanceV3 } from './restful.service';

const getBankUser = () => {
  return axiosInstance.get(BANK.GET_BANK_USER);
};

const getBankSBBSUser = (accountId: string) => {
  return axiosInstanceV3.get(BANK.GET_BANK_SBBS_USER, { params: { accountNo: accountId } });
};

const addBankUser = (params: any) => {
  return axiosInstance.post(BANK.ADD_BANK_USER, params);
};

const deleteBankUser = (params: any) => {
  return axiosInstance.delete(BANK.DELETE_BANK_USER, { data: params });
};

const getListBank = () => {
  return axiosInstance.get(BANK.LIST);
};

const getBankAccountInfo = (bankKey: string, accountNumber: string) => {
  return axiosInstance.get(BANK.GET_BANK_ACCOUNT_INFO(bankKey, accountNumber));
};

export const BankService = {
  getBankUser,
  addBankUser,
  deleteBankUser,
  getListBank,
  getBankAccountInfo,
  getBankSBBSUser,
};
