import MultiCircleSupportBox from 'components/MultiCircleSupportBox';
import { Modal } from 'components/modal/Modal';
import React from 'react';
import { RiMailFill } from 'react-icons/ri';

type EmailSupportModelProps = {
  isShowEmailSupportModal: boolean;
  setIsShowEmailSupportModal: (isShow: boolean) => void;
};

export const EmailSupportModel = ({
  isShowEmailSupportModal,
  setIsShowEmailSupportModal,
}: EmailSupportModelProps) => {
  return (
    <div>
      <Modal
        isOpen={isShowEmailSupportModal}
        hasCloseBtn
        onClose={() => setIsShowEmailSupportModal(false)}
        title={'Hỗ trợ qua email'}
        classNameTitle={'text-center !text-base !leading-[22px]'}
        className={`max-w-[640px]`}
        closeBtnClassName="!text-2xl"
      >
        <div className="mt-4 flex flex-col items-center justify-center gap-6">
          <div>
            <MultiCircleSupportBox
              sizeCircle_1="w-[78.97px] h-[78.97px]"
              sizeCircle_2="w-[134.43px] h-[134.43px]"
              sizeCircle_3="w-[200px] h-[200px]"
              colorCircle_1="bg-primary"
              colorCircle_2="bg-[#154C13]"
              colorCircle_3="bg-[#162D16]"
            >
              <RiMailFill className="w-10 h-10" color="#fff" />
            </MultiCircleSupportBox>
          </div>
          <p className="text-base text-center font-normal leading-[22px] text-text_main_light">
            Bạn cần hỗ trợ trực tiếp, hãy liên hệ với SBBS thông qua <br /> email{' '}
            <span className="font-bold text-white">info@sbbs.com.vn.</span>
          </p>
        </div>
      </Modal>
    </div>
  );
};
