import { ForgotPinButton } from 'components/ForgotPinButton';
import { PasswordVisibilityToggle } from 'components/PasswordVisibilityToggle';
import { SmartOtpGuideButton } from 'components/SmartOtpGuideButton';
import { Button } from 'components/buttons/Button';
import { Countdown } from 'components/countdown';
import { Modal } from 'components/modal/Modal';
import { SpinnerIcon } from 'components/spinner/SpinnerIcon';
import { ErrorToast } from 'components/toasts';
import { AccountService } from 'core';
import { COUNTDOWN_STATUS, TIME_RESEND_OTP, TIME_VALID_OTP } from 'core/constant';
import { useAccount } from 'core/contexts';
import React, { useEffect, useState } from 'react';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

const myWindow = window as any;

type ConfirmPinModalProps = {
  isShowModal: boolean;
  certInfo?: any;
  setIsShowModal: (isShow: boolean) => void;
  handleSubmit: (data: any) => Promise<boolean>;
  handleGetCertInfo?: (data: any) => void;
  successTitle: string;
  successSubTitle: string;
  authenType?: 'PIN' | 'SMART_OTP' | 'SMS_OTP' | 'DIGIT_CERT';
};

enum ModalStepEnum {
  Confirm,
  Success,
}

const ConfirmPinModal = ({
  isShowModal,
  certInfo,
  setIsShowModal,
  handleSubmit,
  handleGetCertInfo,
  successTitle,
  successSubTitle,
  authenType,
}: ConfirmPinModalProps) => {
  const { userInfo } = useAccount();
  const [modalStep, setModalStep] = useState<ModalStepEnum>(ModalStepEnum.Confirm);
  const [error, setError] = useState('');
  const [pin, setPin] = useState('');
  const [onSubmit, setOnSubmit] = useState<boolean>();
  const [isShowPin, setIsShowPin] = useState(false);
  const [countdownOTPStatus, setCountdownOTPStatus] = useState<COUNTDOWN_STATUS>(
    COUNTDOWN_STATUS.END
  );
  const [countdownResendOTPStatus, setCountdownResendOTPStatus] = useState<COUNTDOWN_STATUS>(
    COUNTDOWN_STATUS.END
  );

  const handleCloseModal = () => {
    setIsShowModal(false);
    setTimeout(() => {
      setModalStep(ModalStepEnum.Confirm);
      setError('');
      setPin('');
      setIsShowPin(false);
      setCountdownResendOTPStatus(COUNTDOWN_STATUS.END);
    }, 500);
  };

  const getSmsOtp = async (userId: string) => {
    const res = await AccountService.getSmsOtp(userId);
    const data = res?.data;
    if (data?.status === 'Fail') {
      ErrorToast(data?.error_message.split('|')[1] || data?.error_message);
    }
    if (data?.status === 'Success') {
      setCountdownOTPStatus(COUNTDOWN_STATUS.START);
    }
  };

  const reSendOtp = () => {
    if (userInfo && countdownResendOTPStatus === COUNTDOWN_STATUS.END) {
      setCountdownResendOTPStatus(COUNTDOWN_STATUS.START);
      getSmsOtp(userInfo?.user_id);
    }
  };

  const handleSign = async () => {
    const response = await myWindow.GetCertInfo();
    if (response) {
      handleGetCertInfo && handleGetCertInfo(response);
    }
  };

  const handleClickSubmitBtn = async () => {
    setOnSubmit(true);

    const result = await handleSubmit(pin);
    if (result) {
      setModalStep(ModalStepEnum.Success);
      setOnSubmit(false);
    } else {
      setOnSubmit(false);
    }
  };

  const checkFactorAuthenType = () => {
    if (authenType === 'SMART_OTP' || authenType === 'SMS_OTP' || authenType === 'PIN') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isShowModal && authenType === 'SMS_OTP') {
      reSendOtp();
    }
    if (isShowModal && authenType === 'DIGIT_CERT') {
      myWindow.checkVNPTPlugin();
    }
  }, [isShowModal]);

  return (
    <Modal
      isOpen={isShowModal}
      title={`${
        modalStep === ModalStepEnum.Confirm
          ? authenType === 'SMART_OTP'
            ? 'Nhập Mã Smart OTP'
            : authenType === 'SMS_OTP'
            ? 'Nhập Mã SMS OTP'
            : authenType === 'PIN'
            ? 'Nhập Mã PIN giao dịch'
            : 'Xác nhận giao dịch'
          : successTitle
      }`}
      classNameTitle={`${
        modalStep === ModalStepEnum.Success ? '!text-xl !font-semibold !leading-[26px]' : ''
      }`}
      subtitle={`
        ${
          modalStep === ModalStepEnum.Confirm
            ? authenType === 'SMART_OTP'
              ? 'Nhập Mã Smart OTP để tiếp tục'
              : authenType === 'SMS_OTP'
              ? 'Nhập Mã SMS OTP để tiếp tục'
              : authenType === 'PIN'
              ? 'Nhập mã PIN để tiếp tục'
              : 'Vui lòng chọn chữ ký số để tiếp tục'
            : successSubTitle
        }
        `}
      subtitleClassName={`!text-[14px] !font-normal leading-5 ${
        modalStep === ModalStepEnum.Success && 'text-text_main_light !text-base !leading-[22px]'
      }`}
      onClose={() => {}}
      className={`max-w-[640px]`}
      type={`${modalStep === ModalStepEnum.Success ? 'success' : 'info'}`}
    >
      {modalStep === ModalStepEnum.Confirm && (
        <div className="pt-3">
          {checkFactorAuthenType() ? (
            <>
              <div className="flex items-center justify-between gap-2 mt-8 mb-4">
                <div className="text-sm">
                  {authenType === 'SMART_OTP'
                    ? 'Mã Smart OTP'
                    : authenType === 'SMS_OTP'
                    ? 'Mã SMS OTP'
                    : 'Mã PIN giao dịch'}
                </div>
                {authenType === 'PIN' ? (
                  <div className="checkbox">
                    <input
                      id="save-pin-checkbox"
                      type="checkbox"
                      defaultChecked={!!pin}
                      disabled={!!pin}
                      className={'w-[20px] h-[20px] checkbox__input'}
                      onChange={e => {
                        if (e.target.checked) {
                          localStorage.setItem('IS_SAVE_P', 'TRUE');
                        } else {
                          localStorage.removeItem('IS_SAVE_P');
                        }
                      }}
                    />
                    <label htmlFor="save-pin-checkbox" className={'text-sm checkbox__label'}>
                      Lưu mã PIN
                    </label>
                  </div>
                ) : authenType === 'SMS_OTP' ? (
                  <div className="text-sm">
                    Mã có hiệu lực trong{' '}
                    <span className="font-semibold inline-block min-w-[45px]">
                      <Countdown
                        status={countdownOTPStatus}
                        endTime={TIME_VALID_OTP}
                        onStart={() => setCountdownOTPStatus(COUNTDOWN_STATUS.COUNTING)}
                        onEnd={() => setCountdownOTPStatus(COUNTDOWN_STATUS.END)}
                      />
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="relative">
                <input
                  value={pin}
                  type={isShowPin ? 'text' : 'password'}
                  // disabled={!!pin}
                  className={`w-full !bg-black border rounded-md text-sm pl-3 pr-10 py-2 placeholder:text-text_main_med placeholder:opacity-100`}
                  placeholder={
                    authenType === 'SMART_OTP'
                      ? 'Nhập mã Smart OTP'
                      : authenType === 'SMS_OTP'
                      ? 'Nhập mã SMS OTP'
                      : 'Nhập mã PIN'
                  }
                  defaultValue={pin ? '******' : ''}
                  maxLength={6}
                  onChange={e => {
                    const v = e.target.value;
                    setPin(v);
                  }}
                />
                <button
                  onClick={() => setIsShowPin(!isShowPin)}
                  disabled={!!pin}
                  type="button"
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 disabled:opacity-50"
                >
                  {isShowPin ? <RiEyeLine /> : <RiEyeOffLine />}
                </button>
              </div>
              {authenType === 'PIN' ? (
                <div className="text-right">
                  <ForgotPinButton />
                </div>
              ) : authenType === 'SMART_OTP' ? (
                <div className="text-right">
                  <SmartOtpGuideButton />
                </div>
              ) : authenType === 'SMS_OTP' ? (
                <div className="text-xs mt-4">
                  <div className="mb-1">
                    Vui lòng nhập mã OTP vừa gửi tới {userInfo?.phone_number}
                  </div>
                  <div>
                    Bạn chưa nhận được mã?
                    <button
                      className="font-semibold text-primary ml-2 disabled:opacity-50"
                      onClick={() => reSendOtp()}
                      disabled={countdownResendOTPStatus === COUNTDOWN_STATUS.COUNTING}
                    >
                      Gửi lại mã OTP
                    </button>
                    <span className="hidden">
                      <Countdown
                        status={countdownResendOTPStatus}
                        endTime={TIME_RESEND_OTP}
                        onStart={() => setCountdownResendOTPStatus(COUNTDOWN_STATUS.COUNTING)}
                        onEnd={() => setCountdownResendOTPStatus(COUNTDOWN_STATUS.END)}
                      />
                    </span>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div className="mt-4">
              {certInfo && (
                <p
                  className="text-base float-right font-semibold leading-[22px] text-green-700 cursor-pointer -mb-[2px] mr-4"
                  onClick={handleSign}
                >
                  Chọn lại
                </p>
              )}
              <table className="cert-table">
                <thead>
                  <th>Tên chứng thư số</th>
                  <th>Serial</th>
                  <th className="!text-right">Ngày hết hạn</th>
                </thead>
                <tbody>
                  {certInfo && (
                    <tr>
                      <td>{certInfo?.subjectCN}</td>
                      <td>{certInfo?.serial}</td>
                      <td className="text-right">{certInfo?.notAfter}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {!certInfo && (
                <tr className="pt-5 flex flex-col items-center justify-center w-full">
                  <p className="text-sm leading-5 text-text_main_light">
                    Vui lòng tìm chứng thư số để xác nhận.
                  </p>
                  <Button
                    className="font-semibold mt-8 w-[200px]"
                    variant="secondary"
                    onClick={handleSign}
                  >
                    <span className="text-base leading-[22px]">Chọn chứng thư số</span>
                  </Button>
                </tr>
              )}
            </div>
          )}
        </div>
      )}

      <div className={`flex items-center gap-3 mt-8`}>
        {modalStep == ModalStepEnum.Success && (
          <Button className="flex-1 bg-primary font-semibold text-white" onClick={handleCloseModal}>
            Đóng
          </Button>
        )}

        {modalStep == ModalStepEnum.Confirm && (
          <>
            <Button variant="secondary" className="flex-1" onClick={handleCloseModal}>
              Đóng
            </Button>
            <Button
              variant="primary"
              className="flex-1 flex gap-1 items-center"
              onClick={handleClickSubmitBtn}
              disabled={onSubmit}
            >
              {onSubmit && <SpinnerIcon type="secondary" size="small" />}
              Tiếp theo
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmPinModal;
